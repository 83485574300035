import React, { useRef, useState } from 'react'
import {
  ActionButton,
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Item,
  MoreBar,
  Text,
  TextButton,
  TextSkeleton,
  Token,
  Widget,
  useIntersectViewport,
} from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { formatMoneyWithCode } from '@src/utils/format'
import { EmployeeContractsInterface, EmployeeInterface } from '@src/interfaces/employees'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { InternalLink } from '@components/InternalLink/InternalLink'
import SideBar from '@components/SideBar/SideBar'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { ContractPreviewFields } from '@src/pages/Forms/EmployeeContracts/Preview/common'
import { useActiveProfileSidebar } from '../common'
import { useEmployeeContractsWidget } from './hooks'

type Props = {
  data: EmployeeInterface
}
export const Contracts = ({ data }: Props) => {
  const ref = useRef(null)
  const contracts = useEmployeeContractsWidget(data.id)
  const { sidebar, setSidebarQueryParam } = useActiveProfileSidebar()

  useIntersectViewport(ref, isIntersecting => {
    if (isIntersecting) {
      contracts.handler.load()
    }
  })

  const [isSalaryShown, setIsSalaryShown] = useState(false)

  const isLoaded = contracts.handler.enabled && contracts.handler.status !== 'loading'
  const hasActiveContract = Boolean(isLoaded && contracts.data)
  const showDetailsPreview = hasActiveContract || !isLoaded

  return (
    <>
      <Widget ref={ref}>
        <Item
          variant={hasActiveContract ? 'disclosure' : 'default'}
          use={hasActiveContract ? 'button' : 'div'}
          onClick={
            hasActiveContract
              ? () => setSidebarQueryParam('contractDetails', true)
              : undefined
          }
        >
          <Item.Avatar>
            <Avatar useIcon="Document" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Contract details</Item.Title>
            {isLoaded ? (
              <Item.Description>
                {hasActiveContract
                  ? 'Salary, Bonus, Role, Employment'
                  : "Employee doesn't have active contract"}
              </Item.Description>
            ) : (
              <Item.Description>
                <TextSkeleton width={200} height={12} />
              </Item.Description>
            )}
          </Item.Content>
          <Item.Side>
            {!hasActiveContract && (
              <ActionButton
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.GENERAL, {
                  employeeId: data.id,
                })}
              >
                Add contract
              </ActionButton>
            )}
          </Item.Side>
        </Item>
        {showDetailsPreview && (
          <FormPreview<EmployeeContractsInterface>
            data={contracts.data}
            dataHandler={contracts.handler}
          >
            <FormPreview.Item<EmployeeContractsInterface>
              title="Contract type"
              field="contract_type.name"
            />
            <FormPreview.Item<EmployeeContractsInterface>
              title="Contract start date"
              type="date"
              field="start_date"
            />
            <FormPreview.Item<EmployeeContractsInterface>
              title="Salary"
              insert={d => (
                <HStack align="center" space="s-8">
                  {isSalaryShown ? (
                    <>
                      <Text>
                        {formatMoneyWithCode(
                          d.salary_amount,
                          d.salary_currency?.iso_code,
                        )}
                      </Text>
                      <IconButton
                        useIcon="EyeHide"
                        onClick={() => setIsSalaryShown(false)}
                        color={Token.color.blue}
                        size={16}
                      />
                    </>
                  ) : (
                    <>
                      <Text>****</Text>
                      <IconButton
                        useIcon="EyeShow"
                        onClick={() => setIsSalaryShown(true)}
                        color={Token.color.blue}
                        size={16}
                      />
                    </>
                  )}
                </HStack>
              )}
            />
          </FormPreview>
        )}
        {hasActiveContract && (
          <Flex width="100%" justifyContent="center" p="s-16">
            <TextButton
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CONTRACT, { id: data.id })}
            >
              View all contracts
            </TextButton>
          </Flex>
        )}
      </Widget>
      {!!contracts.data?.id && (
        <SideBar
          title="Contract details"
          subtitle={<UserWithAvatar id={data.id} full_name={data.full_name} />}
          variant="wide"
          isOpen={sidebar === 'contractDetails'}
          onClose={() => setSidebarQueryParam('contractDetails', false)}
          sideProps={{ resizable: true }}
          useIcon="Document"
          avatarProps={{ color: Token.color.greyTone20 }}
        >
          <Box mb="s-12">
            <MoreBar>
              <MoreBar.Action
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.GENERAL, {
                  id: String(contracts.data.id),
                  employeeId: String(data.id),
                })}
                useIcon="Pencil"
              >
                Edit
              </MoreBar.Action>
            </MoreBar>
          </Box>
          <ContractPreviewFields contract={contracts.data} employeeId={String(data.id)} />
        </SideBar>
      )}
    </>
  )
}
