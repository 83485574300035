import React, { useState } from 'react'
import { EligibleGroupInterface, ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import {
  deleteEligibleGroup,
  useGetEligibleGroupsInfo,
  useGetReviewCycle,
} from '@src/api/reviewCycles'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router'
import { Box } from '@revolut/ui-kit'
import { EligibleGroupsFiltersSidebar } from '@src/pages/Forms/ReviewCycle/components/EligibleGroupsFiltersSidebar'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { EligibleGroupActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/EligibleEmployees/EditEligibleGroups/EligibleGroupActions'
import { EligibleGroups } from '@src/pages/Forms/ReviewCycle/ReviewCycle/EligibleEmployees/EditEligibleGroups/EligibleGroups'

export const EditEligibleGroups = () => {
  const params = useParams<{ id: string }>()
  const { data: cycle } = useGetReviewCycle(params.id)
  const { data: groups, refetch, isFetching } = useGetEligibleGroupsInfo(params.id)
  const [currentGroup, setCurrentGroup] = useState<EligibleGroupInterface>()
  const showStatusPopup = useShowStatusPopup()
  const [openSidebar, setOpenSidebar] = useState(false)

  const openSideBar = () => {
    setOpenSidebar(true)
  }

  const closeSideBar = () => {
    setCurrentGroup(undefined)
    setOpenSidebar(false)
  }

  const makeDeleteHandler = (group: EligibleGroupInterface) => async () => {
    await deleteEligibleGroup(group.id)

    closeSideBar()
    showStatusPopup({ title: 'The group was successfully deleted' })

    await refetch()
  }

  const makeEditHandler = (group: EligibleGroupInterface) => () => {
    setCurrentGroup(group)
    openSideBar()
  }

  const hasActions = cycle?.status === ReviewCycleStatus.ongoing
  const backUrl = pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL, params)

  return (
    <PageWrapper>
      <PageHeader title="Eligible employees" backUrl={backUrl} />
      <PageBody>
        {hasActions && (
          <Box mb="s-16">
            <EligibleGroupActions cycle={cycle} />
          </Box>
        )}
        <EligibleGroups
          groups={groups?.results ?? []}
          isLoading={isFetching}
          handleAdd={openSideBar}
          handleEdit={makeEditHandler}
          handleDelete={makeDeleteHandler}
        />
        <EligibleGroupsFiltersSidebar
          isOpen={openSidebar}
          handleClose={closeSideBar}
          groupData={currentGroup}
          refetchGroups={refetch}
        />
      </PageBody>
    </PageWrapper>
  )
}
