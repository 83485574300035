import React from 'react'
import {
  VStack,
  Token,
  Widget,
  HStack,
  Icon,
  useTooltip,
  Tooltip,
  Image,
  Box,
} from '@revolut/ui-kit'
import { Text } from '@src/pages/Landing/components/Text'

interface ItemInterface {
  title: string
  description: string
}

interface ModuleItemProps {
  title: string
  description: string
  image: string
  items: ItemInterface[]
}

const Item = ({ item }: { item: ItemInterface }) => {
  const tooltip = useTooltip()
  return (
    <HStack gap="s-8" key={item.title}>
      <Icon name="Dot" size={16} />
      <Text
        color={Token.color.greyTone50}
        variant="secondary"
        style={{ fontWeight: 700 }}
      >
        {item.title}
      </Text>
      <Icon name="InfoOutline" ml="auto" size={16} {...tooltip.getAnchorProps()} />
      <Tooltip {...tooltip.getTargetProps()}>{item.description}</Tooltip>
    </HStack>
  )
}

export const ModuleItem = ({ title, description, image, items }: ModuleItemProps) => {
  return (
    <Box>
      <Widget p="s-24">
        <Image
          src={`https://assets.revolut.com/assets/3d-images-v2/${image}.png`}
          size={80}
          margin="0 auto"
        />
        <VStack gap="s-16" pt="s-32">
          <VStack gap="s-4">
            <Text variant="h6">{title}</Text>
            <HStack color={Token.color.success} align="center" gap="s-8">
              <Icon name="Check" size={16} />
              <Text variant="primary">Included</Text>
            </HStack>
          </VStack>
          <Text variant="secondary">{description}</Text>
          {items.map(item => (
            <Item item={item} key={item.title} />
          ))}
        </VStack>
      </Widget>
    </Box>
  )
}
