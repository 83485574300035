import React, { useState } from 'react'
import { FilterButton, MoreBar, Token } from '@revolut/ui-kit'

import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@components/TableV2/Table'
import Stat from '@src/components/Stat/Stat'
import {
  AllDocumentsStats,
  EmployeeDocumentListItemInterface,
} from '@src/interfaces/documents'
import {
  documentCategory,
  documentEmployee,
  documentIssueDateTime,
  documentIssuer,
  documentName,
  documentSigningDateTime,
  documentSource,
  documentStatus,
} from '@src/constants/columns/documents'
import { getAllDocuments, getAllDocumentsStats } from '@src/api/documents'
import { getDocumentHighlight } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { TableNames } from '@src/constants/table'
import { useGetDocumentsSettings } from '@src/api/settings'
import { PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { GenericDocumentSidebar } from '@src/features/GenericDocumentSidebar'

const getRow = (
  setDocumentData: (data: EmployeeDocumentListItemInterface) => void,
): RowInterface<EmployeeDocumentListItemInterface> => ({
  linkToForm: data => setDocumentData(data),
  highlight: getDocumentHighlight,
  cells: [
    {
      ...documentName,
      filterKey: null,
      width: 250,
    },
    {
      ...documentCategory,
      width: 200,
    },
    {
      ...documentSource,
      width: 120,
    },
    {
      ...documentEmployee,
      width: 200,
    },
    {
      ...documentIssueDateTime,
      width: 140,
    },
    {
      ...documentIssuer,
      width: 200,
    },
    {
      ...documentSigningDateTime,
      width: 140,
    },
    {
      ...documentStatus,
      width: 200,
    },
  ],
})

const AllDocumentsBase = () => {
  const [documentToPreview, setDocumentToPreview] = useState<
    EmployeeDocumentListItemInterface | undefined
  >()

  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_DOCUMENTS_ASSIGNED_TO_ME,
    false,
  )
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const { data: documentsSettings } = useGetDocumentsSettings()
  const isCommercial = useIsCommercial()

  const showDocumentButtons = permissions.includes(PermissionTypes.UploadDocuments)
  const canManageAutomations =
    !!documentsSettings?.enable_document_requests &&
    permissions.includes(PermissionTypes.ViewDocumentBulkRequest)
  const canManageRules =
    !isCommercial &&
    !!documentsSettings?.enable_document_generation_from_templates &&
    permissions.includes(PermissionTypes.ViewDocumenttemplaterules)
  const canViewDocumentsPreferences = permissions.includes(
    PermissionTypes.ViewDocumentsPreferences,
  )

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'issuer__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = []

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }

  const table = useTable<EmployeeDocumentListItemInterface, AllDocumentsStats>(
    {
      getItems: getAllDocuments,
      getStats: getAllDocumentsStats,
    },
    getInitialFilters(),
  )

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  const getMoreBarCount = () => {
    const alwaysShownBtns = 1

    return (
      alwaysShownBtns +
      Number(!!canManageRules) +
      Number(!!canManageAutomations) +
      Number(!!canViewDocumentsPreferences)
    )
  }

  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          <Stat label="Total" val={table.loading ? undefined : table.stats?.total} />
          <Stat
            label="Pending upload"
            val={table.loading ? undefined : table.stats?.pending_upload}
            color={Token.color.orange}
          />
          <Stat
            label="Pending approval"
            val={table.loading ? undefined : table.stats?.pending_approval}
            color={Token.color.orange}
          />
          <Stat
            label="Pending signature"
            val={table.loading ? undefined : table.stats?.pending_signature}
            color={Token.color.orange}
          />
        </Table.Widget.Info>
        <Table.Widget.Actions>
          {showDocumentButtons && (
            <Table.Widget.MoreBar maxCount={getMoreBarCount()}>
              <MoreBar.Action
                to={pathToUrl(ROUTES.FORMS.DOCUMENT.GENERAL)}
                use={InternalLink}
                useIcon="Plus"
              >
                Add document
              </MoreBar.Action>
              {canManageRules && (
                <MoreBar.Action
                  to={pathToUrl(ROUTES.APPS.DOCUMENTS.RULES)}
                  use={InternalLink}
                  useIcon="Pencil"
                >
                  Manage rules
                </MoreBar.Action>
              )}
              {canManageAutomations && (
                <MoreBar.Action
                  to={pathToUrl(ROUTES.APPS.DOCUMENTS.BULK_REQUEST.LIST)}
                  use={InternalLink}
                  useIcon="Pencil"
                >
                  Manage requests
                </MoreBar.Action>
              )}
              {canViewDocumentsPreferences && (
                <MoreBar.Action
                  to={pathToUrl(ROUTES.SETTINGS.DOCUMENTS)}
                  use={InternalLink}
                  useIcon="Gear"
                >
                  Settings
                </MoreBar.Action>
              )}
              <MoreBar.Action
                to={pathToUrl(ROUTES.FORMS.IMPORT_DATA.DOCUMENTS.UPLOAD_FILE)}
                use={InternalLink}
                useIcon="ShareIOs"
              >
                Import documents
              </MoreBar.Action>
              <Table.ColumnsSettingsButton />
            </Table.Widget.MoreBar>
          )}
        </Table.Widget.Actions>
        <Table.Widget.Filters>
          <FilterButton onClick={onToggleAssignedToMe} active={assignedToMe}>
            My documents
          </FilterButton>
        </Table.Widget.Filters>

        <Table.Widget.Table>
          <AdjustableTable
            hideCount
            name={TableNames.AllDocuments}
            useWindowScroll
            row={getRow(setDocumentToPreview)}
            {...table}
            noDataMessage="All documents will appear here"
            dataType="document"
          />
        </Table.Widget.Table>
      </Table.Widget>
      {!!documentToPreview && (
        <GenericDocumentSidebar
          documentListItem={documentToPreview}
          onClose={() => setDocumentToPreview(undefined)}
        />
      )}
    </>
  )
}

export const AllDocuments = () => {
  return (
    <OnboardingAppScreen category="documents">
      <AllDocumentsBase />
    </OnboardingAppScreen>
  )
}
