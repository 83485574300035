import React from 'react'
import Stat from '@components/Stat/Stat'
import Tooltip from '@components/Tooltip/Tooltip'
import { formatPercentage } from '@src/utils/format'
import { performanceClarification } from '@src/pages/Forms/CommonTalentTab/utils'
import { DataPointSkeleton, HStack } from '@revolut/ui-kit'

type Props = {
  aboveExpectations?: number | null
  belowExpectations?: number | null
  lmCompleted?: number | null
  fmCompleted?: number | null
  isLast?: boolean
  isLoading?: boolean
}

const getStatPercent = (val: number | null) =>
  val !== null ? formatPercentage(val) : 'N/A'

const TalentHeaderStats = ({
  aboveExpectations,
  belowExpectations,
  lmCompleted,
  fmCompleted,
  isLast,
  isLoading,
}: Props) => {
  if (isLoading) {
    return (
      <HStack space="s-16">
        <DataPointSkeleton>
          <DataPointSkeleton.Value />
          <DataPointSkeleton.Label />
        </DataPointSkeleton>
        <DataPointSkeleton>
          <DataPointSkeleton.Value />
          <DataPointSkeleton.Label />
        </DataPointSkeleton>
      </HStack>
    )
  }
  return (
    <>
      {aboveExpectations !== undefined && (
        <Stat
          label="Strong, Exceptional"
          val={
            <Tooltip text={performanceClarification} placement="right">
              {getStatPercent(aboveExpectations)}
            </Tooltip>
          }
          mr="s-32"
        />
      )}
      {belowExpectations !== undefined && (
        <Stat
          label="Avg–, Unsatisfactory"
          val={
            <Tooltip text={performanceClarification} placement="right">
              {getStatPercent(belowExpectations)}
            </Tooltip>
          }
          mr="s-32"
        />
      )}
      {lmCompleted !== undefined && (
        <Stat
          label="LM completion"
          val={
            <Tooltip text={performanceClarification} placement="right">
              {getStatPercent(lmCompleted)}
            </Tooltip>
          }
          mr="s-32"
        />
      )}
      {fmCompleted !== undefined && (
        <Stat
          label="FM completion"
          val={
            <Tooltip text={performanceClarification} placement="right">
              {getStatPercent(fmCompleted)}
            </Tooltip>
          }
          mr={isLast ? 's-8' : 's-32'}
        />
      )}
    </>
  )
}

export default TalentHeaderStats
