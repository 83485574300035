import React from 'react'
import { EntityTypes } from '@src/constants/api'
import {
  analyticsDashboardNameColumn,
  analyticsDashboardRelatedKpisColumn,
  analyticsDashboardOrganisationUnitsColumn,
  analyticsDashboardOwnerColumn,
  analyticsDashboardRatingColumn,
  analyticsDashboardSourceColumn,
  analyticsDashboardRelatedGoalsColumn,
} from '@src/constants/columns/analyticsDashboard'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { RowInterface } from '@src/interfaces/data'
import { AnalyticsDashboardsTab } from '@src/features/AnalyticsDashboardsTab'
import { useGetCompany } from '@src/api/company'
import Loader from '@components/CommonSC/Loader'
import { useGetAnalyticsStats } from '@src/api/analyticsDashboards'
import { analyticsConfig } from '@src/pages/Performance/common'
import { StatNavigation } from '@components/StatNavigation/StatNavigation'
import { useIsNewTable } from '@components/TableV2/hooks'

const initialFilterBy = [
  {
    filters: [{ id: 'true', name: 'true' }],
    columnName: 'company_related',
    nonResettable: true,
  },
]

const row = (isNewTable?: boolean): RowInterface<GenericAnalyticsDashboardInterface> => ({
  cells: [
    {
      ...analyticsDashboardNameColumn,
      width: 150,
    },
    {
      ...analyticsDashboardOrganisationUnitsColumn,
      width: 100,
    },
    {
      ...analyticsDashboardRelatedKpisColumn,
      width: isNewTable ? 50 : 150,
    },
    {
      ...analyticsDashboardRelatedGoalsColumn,
      width: isNewTable ? 100 : 300,
    },
    {
      ...analyticsDashboardRatingColumn,
      width: 30,
    },
    {
      ...analyticsDashboardSourceColumn,
      width: 30,
    },
    {
      ...analyticsDashboardOwnerColumn,
      width: 100,
    },
  ],
})

interface DashboardsProps {
  useStatsNavigation?: boolean
}

export const Dashboards = ({ useStatsNavigation = true }: DashboardsProps) => {
  const isNewTable = useIsNewTable()
  const { data, isLoading } = useGetCompany()

  if (isLoading) {
    return <Loader />
  }

  return (
    <AnalyticsDashboardsTab
      data={data}
      entity={EntityTypes.company}
      initialFilter={initialFilterBy}
      row={row(isNewTable)}
      navigation={
        useStatsNavigation && isNewTable ? (
          <StatNavigation api={useGetAnalyticsStats} config={analyticsConfig} />
        ) : undefined
      }
    />
  )
}

export const DashboardsWithNav = () => {
  return <Dashboards useStatsNavigation />
}

export const DashboardsWithoutNav = () => {
  return <Dashboards useStatsNavigation={false} />
}
