import React from 'react'
import { Text, Token } from '@revolut/ui-kit'
import { InterviewStageChildRow } from '@src/interfaces/interviewTool'
import upperFirst from 'lodash/upperFirst'
import { convertOperators } from '@src/pages/Forms/Candidate/Performance/utils'
import { ColorTag } from '@src/components/ColorTag/ColorTag'
import { isFeedback, isOnlineTest } from '@src/pages/Forms/Candidate/StagesWidget/utils'

type StageChildRatingProps = {
  data: InterviewStageChildRow
}

export const StageChildRating = ({ data }: StageChildRatingProps) => {
  let rating: string | null = null
  if (isFeedback(data) && data.feedback.rating) {
    rating = upperFirst(convertOperators(data.feedback.rating))
  }
  if (isOnlineTest(data) && data.stage.online_test_result?.percentage_score) {
    rating = `${data.stage.online_test_result.percentage_score}%`
  }
  if (!rating) {
    return (
      <Text variant="emphasis2" color={Token.color.greyTone50}>
        n/a
      </Text>
    )
  }
  return <ColorTag variant="neutral">{rating}</ColorTag>
}
