import React, { ChangeEvent } from 'react'
import { Group } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API } from '@src/constants/api'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RoadmapSettingsInterface } from '@src/interfaces/settings'
import { InternalUIKitLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetClickupPreferences, useGetJiraPreferences } from '@src/api/integrations'

export const Roadmaps = () => {
  const { values } = useLapeContext<RoadmapSettingsInterface>()
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const { data: jiraSettings } = useGetJiraPreferences()
  const { data: clickupSettings } = useGetClickupPreferences()

  const disableEdit = !permissions.includes(PermissionTypes.ChangeRoadmapPreferences)

  const handleRoadmapsSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    values.enabled = e.target.checked
    if (!e.target.checked) {
      values.jira_epics_enabled = false
      values.clickup_enabled = false
    }
  }

  return (
    <>
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle title="General" subtitle="All settings related to roadmaps" />

          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable Roadmaps',
                description:
                  'Enable the Roadmaps feature. Allows setting roadmap items and tracking progress.',
              }}
              disabled={disableEdit}
              checked={values.enabled}
              onChange={handleRoadmapsSwitch}
            />
            {values.enabled ? (
              <>
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Manage Roadmaps based on Jira',
                    description: (
                      <>
                        Allows the epics to be selected and updated based on Jira
                        progress. This works only when Jira integration is configured{' '}
                        <InternalUIKitLink
                          // @ts-expect-error object works fine here, but UI kit expects string
                          to={pathToUrl(ROUTES.FEATURES.INTEGRATION.JIRA)}
                        >
                          here
                        </InternalUIKitLink>
                        .
                      </>
                    ),
                  }}
                  disabled={disableEdit || !jiraSettings?.enabled}
                  name="jira_epics_enabled"
                />
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Manage Roadmaps based on ClickUp',
                    description: (
                      <>
                        Allows the epics to be selected and updated based on ClickUp
                        progress. This works only when ClickUp integration is configured{' '}
                        <InternalUIKitLink
                          // @ts-expect-error object works fine here, but UI kit expects string
                          to={pathToUrl(ROUTES.FEATURES.INTEGRATION.CLICKUP)}
                        >
                          here
                        </InternalUIKitLink>
                        .
                      </>
                    ),
                  }}
                  disabled={disableEdit || !clickupSettings?.enabled}
                  name="clickup_enabled"
                />
              </>
            ) : null}
          </Group>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.ROADMAP_SETTINGS)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}
