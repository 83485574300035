import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { getDate, getTimeFromString } from '@src/utils/timezones'
import { HStack, TextWidget, VStack } from '@revolut/ui-kit'
import NewRadioButtons from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import {
  editOptions,
  manualOption,
  scheduledOption,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/EditTimeline/EditOptions'
import { InputGroupWidget } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/EditTimeline/InputGroupWidget'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import { DateTimeInputGroup } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/EditTimeline/DateTimeInputGroup'
import React from 'react'

export const EditFormContent = connect(() => {
  const { values } = useLapeContext<ReviewCyclesInterface>()
  const { data: settings } = useGetPerformanceSettings()

  const makeOnDateTimeChangeHandler =
    (
      key: keyof Pick<
        ReviewCyclesInterface,
        | 'department_kpi_period_start_day'
        | 'department_kpi_period_end_day'
        | 'team_kpi_period_start_day'
        | 'team_kpi_period_end_day'
        | 'individual_kpi_period_start_day'
        | 'individual_kpi_period_end_day'
        | 'review_period_start_day'
        | 'self_and_peer_reviews_last_day'
        | 'managers_reviews_last_day'
        | 'department_owner_calibration_start_day'
        | 'head_of_function_calibration_start_day'
        | 'head_of_function_and_department_last_calibration_day'
        | 'managers_publishing_day'
        | 'reviews_publishing_day'
      >,
    ) =>
    (value?: Date | null) => {
      if (!value) {
        return
      }

      const time = values[key] ? getTimeFromString(String(values[key])) : '00:00'
      values[key] = `${getDate(value)}T${time}:00Z`
    }

  return (
    <VStack space="s-16">
      <TextWidget>
        <TextWidget.Title>How to process cycle stages?</TextWidget.Title>
        <HStack pt="s-16" space="s-72">
          <NewRadioButtons
            options={editOptions}
            value={
              values.is_manual_stage_switch_enabled
                ? manualOption.value
                : scheduledOption.value
            }
            onChange={({ value }) => {
              values.is_manual_stage_switch_enabled = value !== scheduledOption.value
            }}
          />
        </HStack>
      </TextWidget>

      <InputGroupWidget iconName="Flag" title="Goal setting period">
        <HStack space="s-8">
          <LapeDatePickerInput
            name="department_kpi_period_start_day"
            label="Department Goals Start date"
            required
            onChange={makeOnDateTimeChangeHandler('department_kpi_period_start_day')}
          />
          <LapeDatePickerInput
            name="department_kpi_period_end_day"
            label="Department Goals End date"
            required
            onChange={makeOnDateTimeChangeHandler('department_kpi_period_end_day')}
          />
        </HStack>
        <HStack space="s-8">
          <LapeDatePickerInput
            name="team_kpi_period_start_day"
            label="Team Goals Start date"
            required
            onChange={makeOnDateTimeChangeHandler('team_kpi_period_start_day')}
          />
          <LapeDatePickerInput
            name="team_kpi_period_end_day"
            label="Team Goals End date"
            required
            onChange={makeOnDateTimeChangeHandler('team_kpi_period_end_day')}
          />
        </HStack>
        <HStack space="s-8">
          <LapeDatePickerInput
            name="individual_kpi_period_start_day"
            label="Employee Goals Start date"
            required
            onChange={makeOnDateTimeChangeHandler('individual_kpi_period_start_day')}
          />
          <LapeDatePickerInput
            name="individual_kpi_period_end_day"
            label="Employee Goals End date"
            hideOptional
            required
            onChange={makeOnDateTimeChangeHandler('individual_kpi_period_end_day')}
          />
        </HStack>
      </InputGroupWidget>

      <InputGroupWidget iconName="Wealth" title="Performance period">
        <DateTimeInputGroup
          name="review_period_start_day"
          variant="horizontal"
          dateLabel="Start date"
          timeLabel="Start time"
          onChange={makeOnDateTimeChangeHandler('review_period_start_day')}
        />
        <DateTimeInputGroup
          name="self_and_peer_reviews_last_day"
          variant="horizontal"
          dateLabel="Employee End date"
          timeLabel="End time"
          onChange={makeOnDateTimeChangeHandler('self_and_peer_reviews_last_day')}
        />
        <DateTimeInputGroup
          name="managers_reviews_last_day"
          variant="horizontal"
          dateLabel="Manager End date"
          timeLabel="End time"
          onChange={makeOnDateTimeChangeHandler('managers_reviews_last_day')}
        />
      </InputGroupWidget>

      {settings?.enable_calibration && (
        <InputGroupWidget
          iconName="Compare"
          title="Calibration"
          description="If enabled, calibration of grades can occur at department and function
                level by their owners"
        >
          <DateTimeInputGroup
            name="department_owner_calibration_start_day"
            variant="horizontal"
            dateLabel="HoD Calibration Start date"
            timeLabel="Start time"
            onChange={makeOnDateTimeChangeHandler(
              'department_owner_calibration_start_day',
            )}
          />
          <DateTimeInputGroup
            name="head_of_function_calibration_start_day"
            variant="horizontal"
            dateLabel="HoF Calibration Start date"
            timeLabel="Start time"
            onChange={makeOnDateTimeChangeHandler(
              'head_of_function_calibration_start_day',
            )}
          />
          <DateTimeInputGroup
            name="head_of_function_and_department_last_calibration_day"
            variant="horizontal"
            dateLabel="End date"
            timeLabel="End time"
            onChange={makeOnDateTimeChangeHandler(
              'head_of_function_and_department_last_calibration_day',
            )}
          />
        </InputGroupWidget>
      )}

      <InputGroupWidget iconName="Document" title="Publish results">
        <DateTimeInputGroup
          name="managers_publishing_day"
          variant="horizontal"
          dateLabel="Publish to manager"
          timeLabel="Start time"
          onChange={makeOnDateTimeChangeHandler('managers_publishing_day')}
        />
        <DateTimeInputGroup
          name="reviews_publishing_day"
          variant="horizontal"
          dateLabel="Publish to All"
          timeLabel="End time"
          onChange={makeOnDateTimeChangeHandler('reviews_publishing_day')}
        />
      </InputGroupWidget>
    </VStack>
  )
})
