import React from 'react'
import { HStack, Icon, IconName, Text, Token } from '@revolut/ui-kit'
import {
  InterviewFeedbackInterface,
  InterviewStageChildRow,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { removeUnderscore } from '@src/utils/string'
import upperFirst from 'lodash/upperFirst'
import { isFeedback, isOnlineTest } from '@src/pages/Forms/Candidate/StagesWidget/utils'

type StageChildResultProps = {
  data: InterviewStageChildRow
}

type ResultProps = {
  color: string
  iconName?: IconName
  text: string
}

const getFeedbackProps = (feedback: InterviewFeedbackInterface): ResultProps => {
  const { result, status } = feedback
  let iconName: undefined | IconName
  let color = Token.color.greyTone50
  let text = 'n/a'
  if (status === 'rejected') {
    color = Token.color.greyTone50
    text = 'n/a'
  } else if (status === 'completed') {
    switch (result) {
      case 'hire':
        iconName = 'SocialLike'
        color = Token.color.success
        break
      case 'strong_hire':
        iconName = 'SocialLike'
        color = Token.color.success
        break
      case 'no_hire':
        iconName = 'SocialDislike'
        color = Token.color.danger
        break
      case 'strong_no_hire':
        iconName = 'SocialDislike'
        color = Token.color.danger
        break
      default:
        break
    }
    text = result ? removeUnderscore(upperFirst(result)) : ''
  }
  return {
    color,
    iconName,
    text,
  }
}

const getTestProps = (stage: InterviewStageWithoutRoundInterface): ResultProps => {
  if (!stage.online_test_result || stage.online_test_result?.is_passed === null) {
    return {
      color: Token.color.greyTone50,
      text: 'n/a',
    }
  }
  if (stage.online_test_result?.is_passed) {
    return {
      color: Token.color.success,
      iconName: 'SocialLike',
      text: 'Pass',
    }
  }
  return {
    color: Token.color.red,
    iconName: 'SocialDislike',
    text: 'Fail',
  }
}

export const StageChildResult = ({ data }: StageChildResultProps) => {
  let props: ResultProps | null = null
  if (isFeedback(data)) {
    props = getFeedbackProps(data.feedback)
  }
  if (isOnlineTest(data)) {
    props = getTestProps(data.stage)
  }
  if (!props) {
    return null
  }
  return (
    <HStack align="center" gap="s-4" margin="auto">
      {props.iconName && <Icon color={props.color} name={props.iconName} size={16} />}
      <Text color={props.color} variant="body1" style={{ whiteSpace: 'nowrap' }}>
        {props.text}
      </Text>
    </HStack>
  )
}
