import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useNavigation } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useNavigation'

interface Props {
  cycle: ReviewCyclesInterface
}

export const CommonActions = ({ cycle }: Props) => {
  const { id } = cycle
  const { toPerformanceSummary } = useNavigation()

  const handlePreviewScorecardClick = () =>
    navigateTo(pathToUrl(ROUTES.PERFORMANCE.SCORECARD_PREVIEW, { id }))

  return (
    <>
      <MoreBar.Action useIcon="EyeShow" onClick={handlePreviewScorecardClick}>
        Preview scorecard
      </MoreBar.Action>
      <MoreBar.Action useIcon="16/ListBullet" onClick={toPerformanceSummary(cycle)}>
        Performance summary
      </MoreBar.Action>
    </>
  )
}
