import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { QueryObserverResult } from 'react-query'
import styled, { css } from 'styled-components'
import { AxiosPromise } from 'axios'
import { Link, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import upperFirst from 'lodash/upperFirst'
import {
  Action,
  Box,
  DetailsCell,
  ErrorWidget,
  Group,
  HStack,
  Link as UIKitLink,
  Separator,
  Skeleton,
  Subheader,
  Token,
  TransitionCollapse,
  useIntersectViewport,
  VStack,
  Text,
  Ellipsis,
  TextWidget,
  Icon as UIKitIcon,
} from '@revolut/ui-kit'

import { FieldOptions, IdAndName, Params, Statuses } from '@src/interfaces'
import { formatDate, formatMoneyMillions, formatWithoutTimezone } from '@src/utils/format'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import {
  CustomFieldsEntityInterface,
  DynamicGroupIDs,
  SectionOptions,
} from '@src/interfaces/customFields'
import Icon from '@src/components/Icon/Icon'
import { useCustomFields } from '@src/components/Stepper/NewStepperSectionCustomFields'
import { CustomFieldTypes } from '@src/constants/customFields'
import { useQuery } from '@src/utils/queryParamsHooks'
import { getChangelogIcon, PreviewChangelog } from '@components/FormPreview/changelog'
import InputErrorMessage from '@components/InputErrorMessage/InputErrorMessage'
import ShevronToggle from '@components/Atoms/ShevronToggle'
import { Grid } from '@components/CommonSC/Grid'
import { OptionInterface } from '@src/interfaces/selectors'
import { EmployeeProfileSubSections } from '@src/interfaces/employees'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { getValueWithChangelog } from './utils'
import { IsRequiredNoticeIcon } from './IsRequiredNoticeIcon'
import { Status } from '../CommonSC/General'
import capitalize from 'lodash/capitalize'
import { Linkify } from '@components/Linkify/Linkify'

const FitTextWidget = styled(TextWidget)`
  > span {
    padding: 0;
  }
`

const MarginTopCss = css`
  margin-top: ${Token.space.s16};
`

export interface DataHandlerInterface<T> {
  load: () => void
  refetch: () => Promise<QueryObserverResult<T, Error>>
  isRefetching: boolean
  status: 'idle' | 'loading' | 'success' | 'error'
  enabled: boolean
}

interface ErrorStateProps {
  onRefresh: () => void
}

export const ErrorState = ({ onRefresh }: ErrorStateProps) => (
  <ErrorWidget>
    <ErrorWidget.Title>
      <VStack align="center" space="s-8" mt="s-8">
        <UIKitIcon name="ExclamationTriangle" color={Token.color.greyTone20} />
        <Text color={Token.color.greyTone50}>Something went wrong</Text>
      </VStack>
    </ErrorWidget.Title>
    <ErrorWidget.Action onClick={onRefresh}>Refresh</ErrorWidget.Action>
  </ErrorWidget>
)

type FormPreviewContextInterface = {
  data: unknown
  pending: boolean
  changelog?: PreviewChangelog<unknown>
} | null

const FormPreviewContext = createContext<FormPreviewContextInterface>(null)

const useFormPreview = () => {
  const context = useContext(FormPreviewContext)
  if (context == null) {
    throw new Error(`useFormPreview must be wrapped in FormPreviewContext`)
  }
  return context
}

export interface FormPreviewProps<T = any> {
  api?: (params: Params) => AxiosPromise<T>
  title?: string
  titleVariant?: 'default' | 'nested'
  data?: T
  dataHandler?: DataHandlerInterface<T>
  onEdit?: () => void
  hideEdit?: (data?: T) => boolean
  linkTitle?: string
  customHeader?: (data?: T) => React.ReactNode
  children: React.ReactNode
  changelog?: PreviewChangelog<T>
  onDataLoaded?: (data: T) => void
}

export const FormPreview = <T,>({
  api,
  children,
  data: d,
  dataHandler,
  title,
  titleVariant = 'nested',
  onEdit,
  hideEdit,
  linkTitle = 'Edit',
  customHeader,
  changelog,
  onDataLoaded,
}: FormPreviewProps<T>) => {
  const [data, setData] = useState(d)
  const [pending, setPending] = useState(!d)
  const [error, setError] = useState<unknown>()
  const [isInView, setIsInView] = useState(false)

  const params = useParams()
  const ref = useRef(null)

  const fetchData = async () => {
    if (api) {
      setPending(true)
      try {
        const response = await api(params)
        setData(response.data)
        onDataLoaded?.(response.data)
      } catch (err) {
        setError(err)
      } finally {
        setPending(false)
      }
    } else if (dataHandler) {
      if (!dataHandler.enabled) {
        dataHandler.load()
      } else if (dataHandler.status === 'error') {
        setPending(true)
        try {
          const response = await dataHandler.refetch()
          if (response.data) {
            setData(response.data)
            onDataLoaded?.(response.data)
          }
        } catch (err) {
          setError(err)
        } finally {
          setPending(false)
        }
      }
    }
  }

  useEffect(() => {
    if (!api && dataHandler) {
      setError(dataHandler.status === 'error')
    }
  }, [dataHandler?.status])

  useEffect(() => {
    if (isInView) {
      fetchData()
    }
  }, [isInView])

  useIntersectViewport(
    ref,
    isIntersecting => {
      if (!isInView && isIntersecting) {
        setIsInView(true)
      }
    },
    0.3,
  )

  useEffect(() => {
    setData(d)
    setPending(!d)
  }, [d])

  const onRefresh = () => {
    setError(undefined)
    fetchData()
  }

  const contextData = useMemo(
    () => ({
      data,
      pending,
      changelog,
    }),
    [data, pending, changelog],
  )

  const header =
    customHeader?.(data) ||
    (title ? (
      <Subheader variant={titleVariant}>
        <Subheader.Title>{title}</Subheader.Title>
        {onEdit && !hideEdit?.(data) && (
          <Subheader.Side>
            <Action onClick={onEdit}>{linkTitle}</Action>
          </Subheader.Side>
        )}
      </Subheader>
    ) : null)

  if (error) {
    return (
      <Box mb="s-16">
        {header}
        <ErrorState onRefresh={onRefresh} />
      </Box>
    )
  }

  return (
    <Box ref={ref} data-testid={`form_preview_${title}`}>
      <FormPreviewContext.Provider value={contextData}>
        {header}
        {children}
      </FormPreviewContext.Provider>
    </Box>
  )
}

const getValue = <T,>({
  pending,
  insert,
  values,
  field,
  to,
  href,
  type,
  changelog,
}: Pick<ItemProps<T>, 'insert' | 'to' | 'type' | 'field' | 'href'> & {
  pending: boolean
  values?: T
  changelog?: PreviewChangelog<T>
}): { value: React.ReactNode; empty: boolean } => {
  if (pending) {
    return { value: <Skeleton height={16} width={150} />, empty: false }
  }
  if (!values) {
    return { value: '-', empty: true }
  }
  const previousValues = !changelog?.showPendingChanges && changelog?.previousValues

  if (insert) {
    const data = previousValues || values
    const rendered = insert(data)
    const empty = rendered === '-'

    if (to) {
      return {
        value: (
          <UIKitLink use={Link} to={to(values)} target="_blank">
            {rendered}
          </UIKitLink>
        ),
        empty,
      }
    }
    return { value: rendered, empty }
  }

  const value = getValueWithChangelog<T>({ field, values, changelog })

  if (value == null) {
    return { value: '-', empty: true }
  }

  if (href) {
    return {
      value: (
        <UIKitLink use="a" href={href(values)} target="_blank">
          {value}
        </UIKitLink>
      ),
      empty: false,
    }
  }

  if (to) {
    const link = to(values)
    return {
      value: (
        <UIKitLink use={Link} to={link ? getLocationDescriptor(link) : undefined}>
          {value}
        </UIKitLink>
      ),
      empty: false,
    }
  }

  switch (type) {
    case 'date':
      return { value: formatWithoutTimezone(value), empty: false }
    case 'dateTime':
      return {
        value: (
          <Box>
            {formatDate(value)}{' '}
            <Text color={Token.color.greyTone50}>
              {format(new Date(value), 'HH:mm OOO')}
            </Text>
          </Box>
        ),
        empty: false,
      }
    case 'boolean':
      return { value: value ? 'Yes' : 'No', empty: false }
    case 'employee':
      return { value: <UserWithAvatar {...value} />, empty: false }
    case 'money':
      return { value: formatMoneyMillions(value, 'usd'), empty: false }
    case 'options': {
      const rendered = (value as OptionInterface[])?.map(item => item.name)?.join(', ')
      return { value: rendered || '-', empty: !rendered }
    }
    case 'capitalized':
      return { value: upperFirst(value), empty: false }
    case 'link':
      return {
        value: (
          <UIKitLink href={value} target="_blank" rel="noreferrer noopener">
            <UIKitIcon name="LinkExternal" />
          </UIKitLink>
        ),
        empty: false,
      }
    case 'status': {
      return {
        value: value ? (
          <Status status={value as Statuses}>{capitalize(value)}</Status>
        ) : undefined,
        empty: !value,
      }
    }
    case 'showMore': {
      const maxSize = 100
      if (typeof value === 'string' && value.length > maxSize) {
        return {
          value: (
            <FitTextWidget>
              <TextWidget.Summary>
                <Ellipsis tooltip="never">{value}</Ellipsis>
              </TextWidget.Summary>
              <TextWidget.Content>{value}</TextWidget.Content>
            </FitTextWidget>
          ),
          empty: false,
        }
      }
      return {
        value: value || undefined,
        empty: !value,
      }
    }
    default:
      return { value, empty: false }
  }
}

type Types =
  | 'date'
  | 'dateTime'
  | 'boolean'
  | 'employee'
  | 'money'
  | 'options'
  | 'capitalized'
  | 'link'
  | 'status'
  | 'showMore'

interface ItemProps<T = any> {
  field?: string
  title?: React.ReactNode
  to?: (response: T) => string | undefined
  href?: (response: T) => string | undefined
  color?: (response: T) => string
  type?: Types
  insert?: (response: T) => React.ReactNode
  hidden?: boolean
  centered?: boolean
  required?: boolean
  emptyMessage?: string
  loading?: boolean
  hideIfEmpty?: boolean
}

type DetailsProps<T = any> = Omit<ItemProps<T>, 'color'> & { description?: string }

type HeaderProps = {
  title: string
}

interface ExtendableDescriptionProps {
  isDefaultOpen?: boolean
  title: string
  leftInfo?: ReactNode
  content: ReactNode
}

const DetailsWithEmptyFieldMessage: React.FC<{ msg?: string }> = ({ children, msg }) => {
  return (
    <VStack>
      <DetailsCell px="s-16">{children}</DetailsCell>
      {!!msg && (
        <Box pb="s-4">
          <InputErrorMessage message={msg} />
        </Box>
      )}
    </VStack>
  )
}

const Item = <T extends {} | { field_options?: FieldOptions }>({
  field,
  title,
  to,
  color,
  insert,
  type,
  hidden,
  centered,
  href,
  loading,
  required,
  emptyMessage,
  hideIfEmpty,
}: ItemProps<T>) => {
  const { data, pending, changelog } = useFormPreview() as {
    data: T
    pending: boolean
    changelog?: PreviewChangelog<T>
  }
  const { query } = useQuery()
  const changelogActive = !!(query.changes || query.change)

  const fieldHidden =
    data &&
    field &&
    'field_options' in data &&
    data.field_options?.no_access?.includes(field)

  if (hidden || fieldHidden) {
    return null
  }

  const values = changelog?.values || data
  const { value, empty } = getValue({
    pending,
    insert,
    values,
    field,
    to,
    type,
    changelog,
    href,
  })

  if (hideIfEmpty && !value) {
    return null
  }

  const isLoading = pending || loading

  return (
    <DetailsWithEmptyFieldMessage msg={!isLoading && empty ? emptyMessage : undefined}>
      {title && <DetailsCell.Title>{title}</DetailsCell.Title>}
      {isLoading ? (
        <Skeleton height={16} width={150} />
      ) : (
        <DetailsCell.Content
          color={color?.(values)}
          alignSelf={centered ? 'center' : 'auto'}
        >
          <HStack space="s-8" align="center">
            <Text>{value}</Text>
            {required && (
              <IsRequiredNoticeIcon field={field} values={values} changelog={changelog} />
            )}
            {getChangelogIcon(data, field, changelog, changelogActive)}
          </HStack>
        </DetailsCell.Content>
      )}
    </DetailsWithEmptyFieldMessage>
  )
}

const Details = <T extends {} | { field_options?: FieldOptions }>({
  field,
  title,
  description,
  insert,
  to,
  type,
  hidden,
  loading,
  emptyMessage,
}: DetailsProps<T>) => {
  const { data, pending, changelog } = useFormPreview() as {
    data: T
    pending: boolean
    changelog?: PreviewChangelog<T>
  }
  const { query } = useQuery()
  const changelogActive = !!(query.changes || query.change)

  const fieldHidden =
    data &&
    field &&
    'field_options' in data &&
    data.field_options?.no_access?.includes(field)

  if (hidden || fieldHidden) {
    return null
  }

  const values = changelog?.values || data
  const { value, empty } = getValue({
    pending,
    insert,
    values,
    field,
    to,
    type,
    changelog,
  })

  const isLoading = pending || loading

  return (
    <DetailsWithEmptyFieldMessage msg={!isLoading && empty ? emptyMessage : undefined}>
      {title && (
        <DetailsCell.Title>
          {description ? (
            <Box>
              <Text use="p">{title}</Text>
              <Text variant="small">{description}</Text>
            </Box>
          ) : (
            title
          )}
        </DetailsCell.Title>
      )}
      <DetailsCell.Content>
        {getChangelogIcon(data, field, changelog, changelogActive)}
      </DetailsCell.Content>
      {isLoading ? (
        <DetailsCell.Note>
          <Skeleton height={16} />
          <Skeleton height={16} mt="s-8" />
        </DetailsCell.Note>
      ) : (
        <DetailsCell.Note whiteSpace="pre-line">{value || '-'}</DetailsCell.Note>
      )}
    </DetailsWithEmptyFieldMessage>
  )
}

const CollapsibleDescription = ({
  isDefaultOpen,
  leftInfo,
  title,
  content,
}: ExtendableDescriptionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(!!isDefaultOpen)

  return (
    <>
      <DetailsCell onClick={() => setIsOpen(!isOpen)}>
        <DetailsCell.Title>
          <Grid gap={4} flow="column" alignItems="center">
            <Box>{title}</Box>
            <ShevronToggle isOpen={isOpen} />
          </Grid>
        </DetailsCell.Title>
        {leftInfo && <DetailsCell.Content>{leftInfo}</DetailsCell.Content>}
      </DetailsCell>
      <TransitionCollapse in={isOpen}>
        <DetailsCell>
          <DetailsCell.Note>{content}</DetailsCell.Note>
        </DetailsCell>
      </TransitionCollapse>
    </>
  )
}

type ContextProps<T> = {
  insert: (response: T) => React.ReactNode
}

const Context = <T,>({ insert }: ContextProps<T>) => {
  const { data } = useFormPreview() as { data?: T }

  return data ? <>{insert(data)}</> : null
}

interface CustomFieldsInterface {
  sectionId: SectionOptions
  subSectionId?: EmployeeProfileSubSections
  dynamicGroups?: DynamicGroupIDs
  roleId?: number
  departmentId?: number
  requisitionId?: number
  withSpacing?: boolean
  withSeparator?: boolean
}

const CustomFields = (props: CustomFieldsInterface) => {
  const ref = useRef(null)
  const [isInView, setIsInView] = useState(false)

  useIntersectViewport(ref, isIntersecting => {
    if (!isInView && isIntersecting) {
      setIsInView(true)
    }
  })

  return isInView ? <CustomFieldsContents {...props} /> : <Box ref={ref} />
}

export const getCFValue = (
  value:
    | number
    | string
    | OptionInterface
    | OptionInterface[]
    | IdAndName<string>
    | IdAndName<string>[]
    | undefined,
  type: CustomFieldTypes,
) => {
  if (!value) {
    return '-'
  }

  switch (type) {
    case CustomFieldTypes.Number:
    case CustomFieldTypes.Text:
      return value || '-'
    case CustomFieldTypes.Dropdown:
      return typeof value === 'object' && value != null && 'name' in value
        ? value.name
        : '-'
    case CustomFieldTypes.Date:
      return typeof value === 'string' ? formatWithoutTimezone(value) : '-'
    case CustomFieldTypes.MultipleSelector: {
      return Array.isArray(value) && value.length
        ? value.map(opt => opt.name).join(', ')
        : '-'
    }
    default:
      return '-'
  }
}

const CustomFieldsContents = <
  T extends { custom_fields?: CustomFieldsEntityInterface; field_options?: FieldOptions },
>({
  dynamicGroups,
  departmentId,
  roleId,
  requisitionId,
  sectionId,
  subSectionId,
  withSpacing,
  withSeparator,
}: CustomFieldsInterface) => {
  const customFields = useCustomFields({
    sectionId,
    subSectionId,
    dynamicGroups,
    departmentId,
    roleId,
    requisitionId,
  })
  const { data, changelog } = useFormPreview() as {
    data: T
    changelog?: PreviewChangelog<T>
  }
  const { query } = useQuery()
  const changelogActive = !!(query.changes || query.change)

  const custom_fields = (changelog?.values || data)?.custom_fields

  const hidden = data?.field_options?.no_access?.includes('custom_fields')

  if (hidden || !customFields.length || !custom_fields) {
    return null
  }

  return (
    <>
      {withSeparator && <Separator />}
      <Group css={withSpacing ? MarginTopCss : undefined}>
        {customFields.map(cf => {
          const value = custom_fields[cf.uid]

          return (
            <DetailsCell key={cf.uid}>
              <DetailsCell.Title>
                <HStack space="s-8" align="center">
                  <Text>{cf.name}</Text>
                  <Icon type="CF" />
                </HStack>
              </DetailsCell.Title>
              <DetailsCell.Content>
                <HStack space="s-8" align="center">
                  <Text>
                    {cf.input_type.id === CustomFieldTypes.Text ? (
                      <Linkify text={String(getCFValue(value, cf.input_type.id))} />
                    ) : (
                      getCFValue(value, cf.input_type.id)
                    )}
                  </Text>
                  {getChangelogIcon(
                    data,
                    `custom_fields.${cf.uid}`,
                    changelog,
                    changelogActive,
                  )}
                </HStack>
              </DetailsCell.Content>
            </DetailsCell>
          )
        })}
      </Group>
    </>
  )
}

const Header = ({ title }: HeaderProps) => (
  <DetailsCell variant="header" role={undefined}>
    <DetailsCell.Title>{title}</DetailsCell.Title>
  </DetailsCell>
)

export const FormPreviewDivider = styled.hr`
  background-color: ${Token.color.greyTone5} !important;
  height: 1px;
  border: 0;
  margin: 0;
`

FormPreview.Item = Item
FormPreview.Details = Details
FormPreview.CollapsibleDescription = CollapsibleDescription
FormPreview.Divider = FormPreviewDivider
FormPreview.Context = Context
FormPreview.CustomFields = CustomFields
FormPreview.Header = Header
