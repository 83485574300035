import { Box, Flex, Group, Text, TextButton } from '@revolut/ui-kit'
import React from 'react'

interface GlobalSearchSectionProps {
  name: string
  children: React.ReactNode
  showMore?: () => void
  isCoreNavigation: boolean
}

const GlobalSearchSection = ({
  name,
  children,
  showMore,
  isCoreNavigation,
}: GlobalSearchSectionProps) => {
  return (
    <Box mb={isCoreNavigation ? 's-12' : 's-32'}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text
          data-testid="global-search-section-title"
          color="grey-tone-50"
          use="div"
          variant="emphasis2"
          mb={isCoreNavigation ? 's-0' : 's-16'}
        >
          {name}
        </Text>
        {showMore && (
          <TextButton fontSize={14} onClick={showMore}>
            See more
          </TextButton>
        )}
      </Flex>
      <Group
        mx={isCoreNavigation ? '-s-16' : 's-0'}
        // TODO: https://revolut.atlassian.net/browse/REVCOR-3046
        style={{ width: 'auto', background: 'transparent' }}
        data-testid="global-search-section-container"
      >
        {children}
      </Group>
    </Box>
  )
}

export default GlobalSearchSection
