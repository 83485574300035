import React from 'react'
import { IdAndName, Statuses } from '@src/interfaces'
import { KpiGoals } from '@src/interfaces/kpis'
import { isNumber } from 'lodash'

import { mapMetricType } from './mapMetricType'
import { VStack, Text, Token, HStack, Spacer, Icon, Flex } from '@revolut/ui-kit'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { formatNumber } from '@src/utils/format'
import { getPercentColor } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { getStatusColor } from '@src/components/CommonSC/General'
import LongNumberCell from '../Table/AdvancedCells/LongNumberCell/LongNumberCell'

export const GoalMetricCell = (data: {
  initial: number | null | undefined
  current: number | null | undefined
  target: number | null | undefined
  progress: number
  targetType?: KpiGoals
  status?: IdAndName<Statuses>
}) => {
  const { initial, current, target, targetType, progress, status } = data
  if (!isNumber(initial) && !isNumber(current) && !isNumber(target)) {
    return <Text>-</Text>
  }

  const isMaintain = !!(
    targetType &&
    [KpiGoals.keep_target, KpiGoals.keep_target_down, KpiGoals.keep_target_up].includes(
      targetType,
    )
  )

  const metricContent = mapMetricType(targetType)

  return (
    <Tooltip
      placement="right"
      backgroundColor={Token.color.popoverBackground}
      body={
        <VStack space="s-4" p="s-12" width="max-content" minWidth={200}>
          <Text variant="emphasis2" whiteSpace="nowrap" color={Token.color.foreground}>
            {metricContent.name}
          </Text>
          {metricContent.initial && (
            <HStack>
              <Text whiteSpace="nowrap" variant="body3" color={Token.color.greyTone50}>
                {metricContent.initial}
              </Text>
              <Spacer minSize="s-8" />
              <Text variant="body3" color={Token.color.greyTone50}>
                {formatNumber(initial || undefined, 2)}
              </Text>
            </HStack>
          )}
          <HStack space="s-2">
            <Text variant="body3" color={Token.color.greyTone50} whiteSpace="nowrap">
              {metricContent.current}
            </Text>
            {status && (
              <Text
                variant="body3"
                whiteSpace="nowrap"
                ml="s-2"
                color={getStatusColor(status.id)}
              >
                <Text color={Token.color.greyTone50}>(</Text>
                {status.name}
                <Text color={Token.color.greyTone50}>)</Text>
              </Text>
            )}
            <Spacer minSize="s-8" />
            <Text variant="body3" color={getPercentColor(progress * 100)}>
              {formatNumber(current || undefined, 2)}
            </Text>
          </HStack>
          {metricContent.target && (
            <HStack>
              <Text whiteSpace="nowrap" variant="body3" color={Token.color.greyTone50}>
                {metricContent.target}
              </Text>
              <Spacer minSize="s-8" />
              <Text variant="body3" color={Token.color.greyTone50}>
                {formatNumber(target || undefined, 2)}
              </Text>
            </HStack>
          )}
          {metricContent.info && (
            <Text
              pt="s-4"
              variant="body3"
              color={Token.color.greyTone50}
              style={{ fontStyle: 'italic' }}
            >
              {metricContent.info}
            </Text>
          )}
        </VStack>
      }
      justifyContent="flex-start"
      noArrow
    >
      <Flex columnGap="s-4" alignItems="center" width="100%">
        {targetType !== KpiGoals.keep_target_down && (
          <>
            <Text
              variant="emphasis2"
              color={Token.color.greyTone20}
              style={{ justifySelf: 'flex-end' }}
            >
              {isNumber(initial) ? <LongNumberCell preventExpand value={initial} /> : '-'}
            </Text>
            <Icon
              name={isMaintain ? 'NavigationBack' : 'ArrowThinRight'}
              color={Token.color.greyTone20}
              size="s-16"
            />
          </>
        )}
        <Text
          variant="body2"
          color={getPercentColor(progress * 100)}
          style={
            targetType === KpiGoals.keep_target_down
              ? { justifySelf: 'flex-end' }
              : targetType === KpiGoals.keep_target_up
              ? { justifySelf: 'flex-start' }
              : {}
          }
        >
          {isNumber(current) ? <LongNumberCell preventExpand value={current} /> : '-'}
        </Text>
        {targetType !== KpiGoals.keep_target_up && (
          <>
            <Icon
              name={isMaintain ? 'NavigationBack' : 'ArrowThinRight'}
              color={Token.color.greyTone20}
              size="s-16"
            />
            <Text
              variant="emphasis2"
              color={Token.color.greyTone20}
              style={{ justifySelf: 'flex-start' }}
            >
              {isNumber(target) ? <LongNumberCell preventExpand value={target} /> : '-'}
            </Text>
          </>
        )}
      </Flex>
    </Tooltip>
  )
}
