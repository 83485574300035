import { Avatar, HStack, Text, Token, VStack } from '@revolut/ui-kit'
import { UserAvatar } from '@src/components/UserWithAvatar/UserAvatar'
import { UserName } from '@src/components/UserWithAvatar/UserName'
import React from 'react'
import { InterviewStageChildRow } from '@src/interfaces/interviewTool'
import { StageChildInfo } from '@src/pages/Forms/Candidate/StagesWidget/components/StageChildInfo'
import { getInitials } from '@src/utils/employees'
import { formatTimeAgo } from '@src/utils/format'
import {
  isCandidate,
  isFeedback,
  isOnlineTest,
} from '@src/pages/Forms/Candidate/StagesWidget/utils'

type StageChildTitleProps = {
  data: InterviewStageChildRow
}

export const StageChildTitle = ({ data }: StageChildTitleProps) => {
  let info: React.ReactNode = null
  let avatar: React.ReactNode = null
  let text: React.ReactNode = null
  const textVariant = 'body1'

  if (isCandidate(data)) {
    const { candidate, stage } = data
    avatar = <Avatar size={40}>{getInitials(candidate.full_name)}</Avatar>
    text = (
      <Text variant={textVariant} color={Token.color.orange}>
        {stage.scheduling_status === 'pending_candidate_response' &&
          `Pending ${candidate.full_name} response`}
        {stage.scheduling_status === 'scheduling_expired' && 'Interview slots expired'}
      </Text>
    )
    info = stage.updated_date_time ? (
      <Text variant="body2">
        Interview slots sent {formatTimeAgo(stage.updated_date_time)}
      </Text>
    ) : null
  }

  if (isFeedback(data)) {
    const { feedback, stage } = data
    const { interviewer = {} } = feedback
    avatar = <UserAvatar {...interviewer} size={40} />
    text = (
      <UserName
        {...interviewer}
        textProps={{ variant: textVariant }}
        ellipsisProps={{ maxWidth: '200px' }}
      />
    )
    info = <StageChildInfo feedback={feedback} stage={stage} />
  }

  if (isOnlineTest(data)) {
    const { stage } = data
    avatar = <Avatar size={40} useIcon="Laptop" />
    text = <Text variant={textVariant}>{stage.online_test?.name}</Text>
    info = <StageChildInfo stage={stage} />
  }

  if (avatar && text) {
    return (
      <HStack gap="s-8">
        {avatar}
        <VStack>
          {text}
          {info}
        </VStack>
      </HStack>
    )
  }
  return null
}
