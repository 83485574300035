import React from 'react'
import { Caption, Flex, Icon, Link, Token } from '@revolut/ui-kit'
import { InternalLink } from '@components/InternalLink/InternalLink'

interface Props {
  to: string
  linkText: string
  label?: string
}

export const EditLink = ({ to, linkText, label }: Props) => (
  <Flex alignItems="center">
    {label && (
      <Caption pr="s-8" color={Token.color.greyTone50}>
        {label}
      </Caption>
    )}
    <Link to={to} use={InternalLink}>
      <Flex alignItems="center">
        <Icon name="Calendar" size={16} />
        <Caption px="s-4">{linkText}</Caption>
        <Icon name="Pencil" size={16} />
      </Flex>
    </Link>
  </Flex>
)
