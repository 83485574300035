import { SelectOptionItemType } from '@revolut/ui-kit'
import { Id } from '@src/interfaces'
import {
  InterviewStageRow,
  InterviewStageChildRow,
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
  CandidateInterface,
  InterviewStageFeedbackChildRow,
  InterviewStageCandidateChildRow,
  InterviewStageOnlineTestChildRow,
} from '@src/interfaces/interviewTool'

export const isChildRow = (data: InterviewStageRow): data is InterviewStageChildRow =>
  'type' in data

export const isFeedback = (
  data: InterviewStageRow,
): data is InterviewStageFeedbackChildRow => isChildRow(data) && data.type === 'feedback'

export const isCandidate = (
  data: InterviewStageRow,
): data is InterviewStageCandidateChildRow =>
  isChildRow(data) && data.type === 'candidate'

export const isOnlineTest = (
  data: InterviewStageRow,
): data is InterviewStageOnlineTestChildRow =>
  isChildRow(data) && data.type === 'onlineTest'

export type NewOption = Id<'new'>
export type OptionType = InterviewRoundInterface | NewOption
export type SelectOptionType = SelectOptionItemType<OptionType>

export const isNewOption = (
  option: SelectOptionType,
): option is SelectOptionItemType<NewOption> => String(option.key) === 'new'

export const isInterviewRoundSelectOption = (
  option: SelectOptionType,
): option is SelectOptionItemType<InterviewRoundInterface> => String(option.key) !== 'new'

export const isInterviewRound = (option: OptionType): option is InterviewRoundInterface =>
  'specialisation' in option

export const isArchivedOpportunityOption = (option?: OptionType) =>
  !!(option && 'archived_reason' in option && !!option.archived_reason)

export const mapStageToInterviewStageRow = (
  stage: InterviewStageWithoutRoundInterface,
  candidate: CandidateInterface,
): InterviewStageRow => {
  let children: InterviewStageChildRow[] = []
  if (stage.interview_feedbacks.length) {
    stage.interview_feedbacks.forEach(feedback => {
      children.push({ feedback, stage, type: 'feedback' })
    })
  }
  if (stage.online_test && stage.online_test_result) {
    children.push({ stage, type: 'onlineTest' })
  }
  const candidateSchedulingStatuses = ['pending_candidate_response', 'scheduling_expired']
  if (candidateSchedulingStatuses.includes(stage.scheduling_status)) {
    children.push({ candidate, stage, type: 'candidate' })
  }
  return {
    stage,
    children,
  }
}
