import React, { useMemo } from 'react'
import { opacity, Token, VStack, Widget } from '@revolut/ui-kit'
import { HiringStageSwitcher } from '@src/pages/Forms/Candidate/StagesWidget/HiringStageSwitcher'
import {
  CandidateInterface,
  InterviewRoundInterface,
  InterviewStageRow,
  InterviewStageWithoutRoundInterface,
  InterviewStatuses,
  StagesWidgetOnClickHandler,
  StagesWidgetRowOptions,
} from '@src/interfaces/interviewTool'
import { FilterBar } from '@src/pages/Forms/Candidate/StagesWidget/FilterBar'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  createCandidateInterviewStagesTitle,
  candidateInterviewStagesRecommendation,
  createCandidateInterviewStagesRating,
} from '@src/constants/columns/candidateInterviewStages'
import { LocalStorageKeys, PermissionTypes } from '@src/store/auth/types'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import {
  isChildRow,
  isFeedback,
  mapStageToInterviewStageRow,
} from '@src/pages/Forms/Candidate/StagesWidget/utils'

const ROW = (options: StagesWidgetRowOptions): RowInterface<InterviewStageRow> => ({
  noChildrenRequest: true,
  highlight: interviewStageRow => {
    if (isChildRow(interviewStageRow)) {
      return opacity(Token.colorChannel.grey50, 0.04)
    }
    return Token.color.widgetBackground
  },
  cells: [
    { ...createCandidateInterviewStagesTitle(options), width: 500 },
    { ...candidateInterviewStagesRecommendation, width: 130 },
    { ...createCandidateInterviewStagesRating(options), width: 120 },
  ],
})

const getAssessmentOnlyStages = (stages?: InterviewStageWithoutRoundInterface[]) => {
  const assessmentTypes = [
    'home_assessment',
    'skills_round',
    'online_test',
    'test_review',
    'final_round',
  ]
  return (stages || [])?.filter(({ interview_type }) =>
    assessmentTypes.includes(interview_type),
  )
}

type StagesWidgetProps = {
  candidate: CandidateInterface
  canViewEditOffer: boolean
  disableActions: boolean
  loading: boolean
  round?: InterviewRoundInterface
  stages: InterviewStageWithoutRoundInterface[]
  onClick?: StagesWidgetOnClickHandler
  onRefresh: () => void
  onRoundChange: (roundId: number) => void
}

export const StagesWidget = ({
  candidate,
  canViewEditOffer,
  disableActions,
  loading,
  round,
  stages,
  onClick,
  onRefresh,
  onRoundChange,
}: StagesWidgetProps) => {
  const [assessmentOnly, changeAssessmentOnly] = useLocalStorage(
    LocalStorageKeys.SHOW_ASSESSMENT_ONLY,
    false,
  )
  const [feedbackCompleted, changeFeedbackCompleted] = useLocalStorage(
    LocalStorageKeys.SHOW_FEEDBACK_COMPLETED,
    false,
  )
  const canCancel = !!round?.field_options?.permissions?.includes(
    PermissionTypes.CancelPendingScorecard,
  )
  const canAddFeedback = !!round?.field_options?.permissions?.includes(
    PermissionTypes.SeeAddFeedbackButton,
  )
  const displayStages = useMemo(() => {
    let currentStages = stages
    if (assessmentOnly) {
      currentStages = getAssessmentOnlyStages(currentStages)
    }
    if (feedbackCompleted) {
      currentStages = currentStages.filter(
        ({ scheduling_status }) =>
          scheduling_status === InterviewStatuses.feedback_submitted,
      )
    }
    return currentStages
  }, [stages, assessmentOnly, feedbackCompleted])
  const withChildren = useMemo(() => {
    return displayStages.map(stage => mapStageToInterviewStageRow(stage, candidate))
  }, [displayStages])
  if (!round || !candidate) {
    return null
  }
  return (
    <VStack gap="s-16" data-testid="Stages widget">
      <HiringStageSwitcher candidate={candidate} round={round} onChange={onRoundChange} />
      <FilterBar
        assessmentOnly={assessmentOnly}
        feedbackCompleted={feedbackCompleted}
        onAssessmentOnlyChange={() => {
          changeAssessmentOnly(!assessmentOnly)
        }}
        onFeedbackCompletedChange={() => {
          changeFeedbackCompleted(!feedbackCompleted)
        }}
      />
      <Widget>
        <AdjustableTable<InterviewStageRow>
          childrenOpenByDefault
          count={withChildren?.length}
          data={withChildren}
          dataType="Hiring stage"
          enableSettings={false}
          expandableType="chevron"
          hideCount
          loading={loading}
          lockFirstColumn={false}
          name={TableNames.CandidateHiringStages}
          noDataMessage="There are no interviews for this candidate"
          noReset
          row={ROW({
            round,
            candidate,
            stages: displayStages,
            canAddFeedback,
            canCancel,
            canViewEditOffer,
            disableActions,
            onRefresh,
            onClick,
          })}
          onRowClick={row => {
            const data = isFeedback(row) ? row?.feedback || row.stage : row.stage
            onClick?.(data, undefined, data.interview_type)
          }}
        />
      </Widget>
    </VStack>
  )
}
