import React from 'react'
import { Box, Flex, MoreBar, Image, VStack, StatusWidget, Token } from '@revolut/ui-kit'

import { useGenericFileApi, useUploadedDocumentsApi } from './hooks'
import Loader from '@src/components/CommonSC/Loader'

type WidgetWrapperProps = {
  children: React.ReactNode
  hasPreviewContainerHeight?: boolean
}
const WidgetWrapper = ({ children, hasPreviewContainerHeight }: WidgetWrapperProps) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    flex="1 0"
    width="100%"
    height={hasPreviewContainerHeight ? '100%' : 320}
    backgroundColor={Token.color.widgetBackground}
    borderRadius={Token.radius.widget}
  >
    {children}
  </Flex>
)

interface Props<T> {
  id: number
  document?: T | null
  genericFileInfo?: { id: number; name: string }
  renderDetails?: () => React.ReactNode
  renderActions?: (
    downloadButton?: React.ReactNode,
    isLoading?: boolean,
  ) => React.ReactNode
  renderAbovePreview?: () => React.ReactNode
  pending?: boolean
  previewContainerHeight?: number
}

export const DocumentPreview = <
  T extends { id: number; file_name: string; file_media_type: string },
>({
  id,
  pending,
  document,
  genericFileInfo,
  renderDetails,
  renderActions,
  renderAbovePreview,
  previewContainerHeight,
}: Props<T>) => {
  if (!pending && !document && !genericFileInfo) {
    throw new Error('File params are not specified')
  }
  const uploadedDocumentFileCtx = useUploadedDocumentsApi(id, document)
  const genericFileCtx = useGenericFileApi(id, genericFileInfo)

  const {
    isLoading = false,
    isError,
    canPreview,
    canSave,
    onSave,
    isPdf,
    isImage,
    fileUrl,
  } = genericFileInfo ? genericFileCtx : uploadedDocumentFileCtx

  const renderPreview = () => {
    if (pending || isLoading) {
      return (
        <WidgetWrapper hasPreviewContainerHeight={!!previewContainerHeight}>
          <Loader />
        </WidgetWrapper>
      )
    }
    if (!fileUrl) {
      return (
        <WidgetWrapper hasPreviewContainerHeight={!!previewContainerHeight}>
          {isError ? (
            <StatusWidget>
              <StatusWidget.Image
                image={{
                  default: 'https://assets.revolut.com/assets/3d-images-v2/3D358.png',
                  '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D358@2x.png',
                  '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D358@3x.png',
                }}
              />
              <StatusWidget.Title>Failed to load document preview</StatusWidget.Title>
              <StatusWidget.Description>
                An error report has been sent
              </StatusWidget.Description>
            </StatusWidget>
          ) : (
            <StatusWidget>
              <StatusWidget.Image
                image={{
                  default: 'https://assets.revolut.com/assets/3d-images-v2/3D299.png',
                  '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D299@2x.png',
                  '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D299@3x.png',
                }}
              />
              <StatusWidget.Title>No files attached</StatusWidget.Title>
            </StatusWidget>
          )}
        </WidgetWrapper>
      )
    }
    if (!canPreview) {
      return (
        <WidgetWrapper hasPreviewContainerHeight={!!previewContainerHeight}>
          <StatusWidget>
            <StatusWidget.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images-v2/3D310.png',
                '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D310@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D310@3x.png',
              }}
            />
            <StatusWidget.Title>This document can't be previewed</StatusWidget.Title>
          </StatusWidget>
        </WidgetWrapper>
      )
    }
    if (isPdf) {
      return (
        <iframe
          src={`${fileUrl}#view=fitH&toolbar=0&navpanes=0`}
          width="100%"
          height="100%"
          frameBorder="0"
          title="Document"
        />
      )
    }
    if (isImage) {
      return <Image src={fileUrl} />
    }
    return null
  }

  const downloadButton = (
    <MoreBar.Action
      useIcon="Download"
      disabled={Boolean(isLoading || !canSave)}
      onClick={onSave}
    >
      Download
    </MoreBar.Action>
  )

  const height = previewContainerHeight
    ? `${previewContainerHeight}px`
    : 'calc(100vh - 230px)'

  return (
    <VStack space="s-24">
      <VStack space="s-16">
        <Box>
          {renderActions?.(downloadButton, isLoading) || (
            <MoreBar>{downloadButton}</MoreBar>
          )}
        </Box>
        {renderAbovePreview && <Box>{renderAbovePreview?.()}</Box>}
        <Box style={{ height }} overflow="scroll">
          {renderPreview()}
        </Box>
      </VStack>
      {renderDetails?.()}
    </VStack>
  )
}
