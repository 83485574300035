import React, {
  createContext,
  useReducer,
  PropsWithChildren,
  Reducer,
  Dispatch,
  useContext,
} from 'react'
import { Token } from '@revolut/ui-kit'
import styled from 'styled-components'
import { useIsNewTable } from '@components/TableV2/hooks'
import { TableWidget } from '@components/TableV2/TableProvider/components/TableWidget'
import { TableMoreBar } from '@components/TableV2/TableProvider/components/TableMoreBar'

export enum ActionType {
  OpenSettings = 'open-settings',
  CloseSettings = 'close-settings',
}

type Action = {
  type: ActionType
}

type State = {
  settingsOpen: boolean
}

const StyledTableWidget = styled(TableWidget)({
  padding: `${Token.space.s16} 0 0 0`,
  '& > span > :not(:last-child)': {
    paddingLeft: Token.space.s16,
    paddingRight: Token.space.s16,
  },
})

export const TableContext = createContext({ settingsOpen: false })
export const TableDispatchContext = createContext<Dispatch<Action>>(() => null)

export function useTableContext() {
  return useContext(TableContext)
}

export function useTableDispatch() {
  return useContext(TableDispatchContext)
}

const TableProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(tableReducer, {
    settingsOpen: false,
  })

  const isNewTable = useIsNewTable()
  const TableWidgetComp = isNewTable ? StyledTableWidget : TableWidget

  return (
    <TableContext.Provider value={state}>
      <TableDispatchContext.Provider value={dispatch}>
        <TableWidgetComp>{children}</TableWidgetComp>
      </TableDispatchContext.Provider>
    </TableContext.Provider>
  )
}

function tableReducer(state: State, action: Action) {
  switch (action.type) {
    case ActionType.OpenSettings: {
      return { ...state, settingsOpen: true }
    }
    case ActionType.CloseSettings: {
      return { ...state, settingsOpen: false }
    }
    default: {
      throw Error(`Unknown action: ${action}`)
    }
  }
}

TableProvider.Info = TableWidget.Info
TableProvider.Table = TableWidget.Table
TableProvider.Search = TableWidget.Search
TableProvider.Filters = TableWidget.Filters
TableProvider.Actions = TableWidget.Actions
TableProvider.Status = TableWidget.Status
TableProvider.MoreBar = TableMoreBar

export default TableProvider
