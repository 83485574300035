import React from 'react'

import { createHost, createSlot } from 'create-slots'
import {
  Bar,
  Box,
  Highlights,
  Text,
  WidgetProps,
  Widget,
  Token,
  Grid,
  VStack,
  rem,
  TextStyle,
} from '@revolut/ui-kit'
import { useIsNewTable } from '@components/TableV2/hooks'

const TableSlot = createSlot('span')
const SearchSlot = createSlot(Box)
const InfoSlot = createSlot(Highlights)
const StatusSlot = createSlot(Text)
const ActionsSlot = createSlot('span')
const FiltersSlot = createSlot(Bar)

export interface TableWidgetProps extends WidgetProps {}

export const TableWidget = (props: TableWidgetProps) => {
  const isNewTable = useIsNewTable()

  return (
    <Widget p={Token.space.s16} {...props}>
      {createHost(props.children, Slots => {
        const infoProps = Slots.getProps(InfoSlot)
        const search = Slots.get(SearchSlot)
        const actions = Slots.get(ActionsSlot)
        const filters = Slots.get(FiltersSlot)
        const statusProps = Slots.getProps(StatusSlot)
        return (
          <VStack space={Token.space.s16}>
            {(infoProps || search) && (
              <Grid
                columns={{
                  all: '1fr',
                  md: search ? `auto minmax(auto, ${rem(343)})` : '1fr',
                }}
                gap={Token.space.s16}
                placeContent="stretch space-between"
                placeItems="center stretch"
              >
                {infoProps && <Highlights {...infoProps} />}
                {search}
              </Grid>
            )}
            {(actions || filters) && (
              <Grid
                columns={
                  isNewTable
                    ? filters
                      ? 'auto minmax(0, auto)'
                      : '1fr'
                    : 'minmax(0, auto) auto'
                }
                gap={Token.space.s16}
                placeContent="stretch space-between"
                placeItems="center stretch"
              >
                {isNewTable ? filters : actions}
                {isNewTable ? actions : filters}
              </Grid>
            )}
            <VStack space={Token.space.s8}>
              {statusProps && (
                <Box px={Token.space.s16}>
                  <Text
                    variant={TextStyle.CAPTION}
                    color={Token.color.greyTone50}
                    {...statusProps}
                  />
                </Box>
              )}
              {Slots.get(TableSlot)}
            </VStack>
          </VStack>
        )
      })}
    </Widget>
  )
}

TableWidget.Table = TableSlot
TableWidget.Search = SearchSlot
TableWidget.Info = InfoSlot
TableWidget.Status = StatusSlot
TableWidget.Actions = ActionsSlot
TableWidget.Filters = FiltersSlot
