import React, { useState } from 'react'
import CancelRequestPopup from '@src/pages/Forms/Candidate/InterviewProgress/components/Popups/CancelRequestPopup'
import { DropdownItemProps } from '@revolut/ui-kit'
import MenuAction, { MenuActionType } from '@components/MenuAction/MenuAction'

type Props = {
  id: number
  itemProps?: DropdownItemProps
  onRefresh?: (hard?: boolean) => void
  menuType: MenuActionType
}

const RejectFeedbackButton = ({ id, onRefresh, itemProps, menuType }: Props) => {
  const [isCancelPopupOpen, setCancelPopupOpen] = useState(false)

  const cancelHandler = () => {
    setCancelPopupOpen(true)
  }

  return (
    <>
      <MenuAction
        menuType={menuType}
        props={{
          onClick: cancelHandler,
          useIcon: 'Reverted',
          ...itemProps,
        }}
      >
        Decline feedback
      </MenuAction>
      <CancelRequestPopup
        id={id}
        isOpen={isCancelPopupOpen}
        onSuccess={() => onRefresh?.(true)}
        onClose={() => setCancelPopupOpen(false)}
      />
    </>
  )
}

export default RejectFeedbackButton
