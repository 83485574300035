import React from 'react'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { notReachable } from '@src/utils/notReachable'
import { GoalsStats } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Details/GoalsStats/GoalsStats'
import { GoalsOwner } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/GoalsModel'
import { ReviewStats } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Details/ReviewsStats/ReviewsStats'

interface Props {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
}

export const StageStats = ({ cycle, stage }: Props) => {
  switch (stage) {
    case ReviewCycleStage.TeamGoals:
      return <GoalsStats cycle={cycle} goalsOwner={GoalsOwner.Team} />
    case ReviewCycleStage.DepartmentGoals:
      return <GoalsStats cycle={cycle} goalsOwner={GoalsOwner.Department} />
    case ReviewCycleStage.Review:
      return <ReviewStats cycle={cycle} />
    case ReviewCycleStage.Calibration:
    case ReviewCycleStage.ManagersPublish:
    case ReviewCycleStage.EmployeesPublish:
      return null

    default:
      return notReachable(stage)
  }
}
