import { selectorKeys } from '@src/constants/api'
import { useEngagementResultStats, useEngagementSurveyRounds } from '@src/api/engagement'
import { useGetSelectors } from '@src/api/selectors'

export const useGetNPS = () => {
  const { data: surveys, isLoading: isSurveysLoading } = useGetSelectors(
    selectorKeys.engagement_survey_titles_sorted,
  )
  const { data: roundsData, isLoading: isRoundsLoading } = useEngagementSurveyRounds(
    surveys?.[0]?.id,
  )

  const { data: stats, isLoading } = useEngagementResultStats(
    surveys?.[0]?.id,
    roundsData?.results?.[0]?.id,
  )

  return {
    isLoading: isLoading || isSurveysLoading || isRoundsLoading,
    score: stats?.nps_score,
  }
}
