import { Avatar, Box, Tip } from '@revolut/ui-kit'
import React from 'react'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { stageToWidgetElementsMap } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/constants'
import { StageDetails } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Details/StageDetails'
import { StageActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/StageActions'

interface Props {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
}

export const StageWidget = ({ cycle, stage }: Props) => {
  const { iconName, title, description } = stageToWidgetElementsMap[stage]

  return (
    <Tip>
      <Tip.Avatar>
        <Avatar useIcon={iconName} />
      </Tip.Avatar>
      <Tip.Title>{title}</Tip.Title>
      <Tip.Description>{description}</Tip.Description>
      <Tip.Actions>
        <StageDetails cycle={cycle} stage={stage} />
        <Box mt="s-8">
          <StageActions cycle={cycle} stage={stage} />
        </Box>
      </Tip.Actions>
    </Tip>
  )
}
