import { KpiGoals } from '@src/interfaces/kpis'

export const mapMetricType = (type?: KpiGoals) => {
  switch (type) {
    case KpiGoals.increase:
      return {
        name: 'Increase metric',
        initial: 'Initial value',
        target: 'Target value',
        current: 'Current value',
      }
    case KpiGoals.decrease:
      return {
        name: 'Decrease metric',
        initial: 'Initial value',
        target: 'Target value',
        current: 'Current value',
      }
    case KpiGoals.keep_target:
      return {
        name: 'Maintain metric',
        initial: 'Lower limit',
        target: 'Upper limit',
        current: 'Current value',
        info: 'Keep the value within the limits to achieve this goal',
      }
    case KpiGoals.keep_target_down:
      return {
        name: 'Maintain down metric',
        target: 'Upper limit',
        current: 'Current value',
        info: 'Keep the value below the limit to achieve this goal',
      }
    case KpiGoals.keep_target_up:
      return {
        name: 'Maintain up metric',
        initial: 'Lower limit',
        current: 'Current value',
        info: 'Keep the value above the limit to achieve this goal',
      }
    default:
      return {
        name: 'Metric info',
        initial: 'Initial value',
        target: 'Target value',
        current: 'Current value',
      }
  }
}
