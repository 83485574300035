import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import React from 'react'
import { Goals } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/Goals/Goals'
import { GoalsOwner } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/GoalsModel'
import { notReachable } from '@src/utils/notReachable'
import { GoalsOverview } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/Goals/GoalsOverview'
import { makeGoalsProgressText } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/constants'

interface Props {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
}

type WithScrollRef<T> = T & { scrollRef: React.RefObject<HTMLDivElement> }

export const SidebarContent = ({ cycle, stage, scrollRef }: WithScrollRef<Props>) => {
  switch (stage) {
    case ReviewCycleStage.DepartmentGoals:
      return (
        <Goals cycle={cycle} goalsOwner={GoalsOwner.Department} scrollRef={scrollRef} />
      )
    case ReviewCycleStage.TeamGoals:
      return <Goals cycle={cycle} goalsOwner={GoalsOwner.Team} scrollRef={scrollRef} />
    case ReviewCycleStage.Review:
    case ReviewCycleStage.Calibration:
    case ReviewCycleStage.ManagersPublish:
    case ReviewCycleStage.EmployeesPublish:
      return null
    default:
      return notReachable(stage)
  }
}

export const SidebarSubtitle = ({ cycle, stage }: Props) => {
  switch (stage) {
    case ReviewCycleStage.DepartmentGoals:
      return <GoalsOverview cycle={cycle} goalsOwner={GoalsOwner.Department} />
    case ReviewCycleStage.TeamGoals:
      return <GoalsOverview cycle={cycle} goalsOwner={GoalsOwner.Team} />
    case ReviewCycleStage.Review:
    case ReviewCycleStage.Calibration:
    case ReviewCycleStage.ManagersPublish:
    case ReviewCycleStage.EmployeesPublish:
      return null
    default:
      return notReachable(stage)
  }
}

export const makeSidebarTitle = (stage: ReviewCycleStage): React.ReactNode => {
  switch (stage) {
    case ReviewCycleStage.DepartmentGoals:
      return makeGoalsProgressText(GoalsOwner.Department)
    case ReviewCycleStage.TeamGoals:
      return makeGoalsProgressText(GoalsOwner.Team)
    case ReviewCycleStage.Review:
    case ReviewCycleStage.Calibration:
    case ReviewCycleStage.ManagersPublish:
    case ReviewCycleStage.EmployeesPublish:
      return null
    default:
      return notReachable(stage)
  }
}
