import React, { useEffect, useMemo, useRef, useState } from 'react'
import { startCase, merge, isFunction } from 'lodash'
import { scaleLinear } from 'd3-scale'
import styled from 'styled-components'
import Icon from '../../Icon/Icon'
import { format, isValid } from 'date-fns'
import {
  BarCustomLayer,
  BarMouseEventHandler,
  ResponsiveBar,
  TooltipProp,
  Value,
} from '@nivo/bar'
import { AxiosPromise } from 'axios'
import colorUtil from 'color'
import Tooltip from '../../Tooltip/Tooltip'
import { curveStepBefore, line } from 'd3-shape'
import {
  formatMoney,
  formatMoneyMillions,
  formatNumber,
  formatNumberMillions,
  formatPercentage,
} from '@src/utils/format'
import {
  PerformanceChartCycles,
  PerformanceChartSingleData,
  StackInterface,
  TargetInterface,
} from '@src/interfaces/chart'
import { Legend } from './Legend'
import { PerformanceTimeRange } from '@src/constants/api'
import { ButtonGroup } from '@components/ButtonGroup/ButtonGroup'
import { Box as NivoBox, CartesianMarkerProps, Theme } from '@nivo/core'
import { AxisProps } from '@nivo/axes'
import { Box, Color, Flex, opacity, TabBar, Text, Token } from '@revolut/ui-kit'
import { CHART_COLORS } from '@src/styles/colors'
import useResizeObserver from 'use-resize-observer'

export enum BarLayerType {
  Grid = 'grid',
  Axes = 'axes',
  Bars = 'bars',
  Markers = 'markers',
  Legends = 'legends',
}

export interface BaseInnerPropsInterface {
  cycleOffset?: string
  graphTimeRange?: PerformanceTimeRange
  id: number
  currency?: string
  isPercentageChart?: boolean
  fetchData?: (
    id: string,
    period: PerformanceTimeRange,
    cycleOffset?: string,
  ) => AxiosPromise<PerformanceChartCycles>
  loading?: boolean
  keys?: string[]
  layers?: ((targets: TargetInterface[], currency?: string) => BarCustomLayer)[]
  legend?: React.ReactNode
  colors?: ((bar: any) => string[]) | string[]
  data?: PerformanceChartCycles
  showButtons?: boolean
  buttonSuffix?: React.ReactNode
  markers?: CartesianMarkerProps[]
  findMaxValue?: (maxValue: number) => number
  findMinValue?: (maxValue: number) => number
  formatValue?: (value: number) => number | string
  onClick?: BarMouseEventHandler<SVGElement>
  tooltip?: TooltipProp
  axisBottom?: AxisProps
  axisLeft?: AxisProps
  margin?: NivoBox
  gridYValues?: number[]
  title?: string
  indexBy?: string
  isNew?: boolean
  targetLayerTitle?: string
  targetLayerColor?: Color
  showTargetLegend?: boolean
  highlightHoveredCells?: boolean
  onGraphTimeRangeChange?: (range: PerformanceTimeRange) => void
  legendMode?: 'auto' | 'large' | 'small'
  onResize?: (size: { width: number; height: number }) => void
  theme?: Theme
  isSection?: boolean
}

export const EstimateTooltip = styled.div`
  background: ${Token.color.greyTone50};
  padding: 10px;
  border-radius: 4px;
  color: ${Token.color.background};
`

type SharedChartContainerProps = {
  showButtons?: boolean
  hasVerticalLayout?: boolean
  title?: string
}

const getHeightStyle = (props: SharedChartContainerProps): string => {
  let count = 8
  if (props.showButtons) {
    count = 82
  }
  if (props.title) {
    count += 54
  }

  return `calc(
    100% - ${count}px - ${props.hasVerticalLayout ? '12vh' : '0px'}
  )`
}

const SharedChartContainer = styled.div<SharedChartContainerProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${getHeightStyle}
  width: 100%;
`

const TooltipContainer = styled.div`
  color: ${Token.color.foreground};
`

const TargetTooltipContainer = styled.div`
  white-space: nowrap;
  padding: 8px 12px;
  color: ${Token.color.background};
`

const Loader = styled(Icon)`
  color: ${Token.color.greyTone50};
`

const ButtonRow = styled.div`
  padding-bottom: 16px;
  padding-left: 32px;
  padding-top: 32px;
  display: flex;
`

const FetchingError = styled.div``

const maxRatio = 3 / 4.5

const getMaxTicks = (
  width?: number,
  height?: number,
): { x: number; y: number } | null => {
  if (!width || !height) {
    return null
  }
  let x: number = Math.ceil(width / 100)
  let y: number = Math.ceil(height / 70)

  if (width <= parseInt(Token.breakpoint.sm, 10)) {
    x = 4
    y = 2
  } else if (width <= parseInt(Token.breakpoint.md, 10)) {
    x = 10
    y = 2
  }
  return { x, y }
}

const canShowCycles = (width?: number) => {
  return width && width > parseInt(Token.breakpoint.md, 10)
}

const getBarPadding = (width?: number) => {
  return width && width > parseInt(Token.breakpoint.md, 10) ? 0.6 : 0.7
}

const predictionLayer =
  (tooltip?: TooltipProp) =>
  ({
    bars,
    yScale,
    xScale,
    data,
    showTooltip,
    hideTooltip,
    onMouseEnter,
    onMouseLeave,
  }: any) => {
    const width = bars[0]?.width || 10

    const handleMouseEnter =
      (estimate: number | string, progress_datetime: string) =>
      (e: React.MouseEvent<SVGRectElement>) => {
        onMouseEnter(data, e)
        const component = tooltip ? (
          <EstimateTooltip>
            Estimated:{' '}
            {tooltip({ value: estimate, indexValue: progress_datetime } as any)}
          </EstimateTooltip>
        ) : (
          <EstimateTooltip>Estimated: {formatNumber(estimate as number)}</EstimateTooltip>
        )

        showTooltip(component, e)
      }
    const handleMouseLeave = (e: React.MouseEvent<SVGRectElement>) => {
      onMouseLeave(data, e)
      hideTooltip(e)
    }

    return (
      <>
        {data.map(
          ({ estimated, progress_datetime }: PerformanceChartSingleData, i: number) => {
            if (!estimated) {
              return null
            }
            const height =
              yScale(0) - yScale(estimated) > 0
                ? yScale(0) - yScale(estimated)
                : yScale(estimated) - yScale(0)
            return (
              <g key={i}>
                <rect
                  onMouseEnter={handleMouseEnter(estimated, progress_datetime)}
                  onMouseMove={handleMouseEnter(estimated, progress_datetime)}
                  onMouseLeave={handleMouseLeave}
                  x={xScale(progress_datetime)}
                  y={yScale(0) - yScale(estimated) > 0 ? yScale(estimated) : yScale(0)}
                  width={width}
                  height={height}
                  strokeWidth={1}
                  strokeDasharray={5}
                  stroke={Token.color.greyTone20}
                  fill={opacity(Token.colorChannel.deepGrey, 0.1)}
                />
              </g>
            )
          },
        )}
      </>
    )
  }

const TargetTooltip = ({
  target,
  currency,
  title,
}: {
  target: TargetInterface
  title: string
  currency?: string
}) => {
  return (
    <TargetTooltipContainer>
      {target.target_name || target.review_cycle_name} {title?.toLowerCase()}:
      <br />
      {currency ? formatMoney(target.target, currency) : formatNumber(target.target)}
    </TargetTooltipContainer>
  )
}

const cycleLayer = (targets: TargetInterface[]) => {
  return ({ width, height, xScale, bars, data }: any) => {
    const barWidth = bars[0]?.width || 10
    if (!targets?.length) {
      return null
    }

    const maxIndex = targets.reduce(
      (prev, curr) => (prev > curr.end_index ? prev : curr.end_index),
      0,
    )
    const xCustomScale = scaleLinear().domain([0, maxIndex]).range([0, width])

    const getEndX = (target: TargetInterface, index: number) => {
      const endData = data[target.end_index]
      let endX = xCustomScale(0)

      if (endData && index !== 0) {
        endX = xScale(endData.progress_datetime) - barWidth * 0.2 - 1.5
      } else if (index === targets.length - 1) {
        endX = xCustomScale(maxIndex)
      }
      return endX
    }

    return (
      <>
        {targets.map((target, index) => {
          const startData = data[target.start_index]
          const endX = getEndX(target, index)
          let startX = xCustomScale(0)

          if (startData && index !== 0) {
            startX = xScale(startData.progress_datetime) - barWidth * 0.2 - 1.5
          } else if (index === targets.length - 1) {
            startX = xCustomScale(maxIndex)
          }

          return (
            <React.Fragment key={index}>
              <text
                fontSize="12"
                textAnchor="middle"
                fill={Token.color.foreground}
                x={endX - (endX - startX) / 2}
                y={0}
              >
                {target.review_cycle_name}
              </text>
              <path
                d={`M ${endX} 10 L ${endX} ${height}`}
                fill="none"
                stroke={Token.color.blue_20}
                strokeLinecap="round"
                strokeWidth="1"
                strokeDasharray="5,5"
                style={{ pointerEvents: 'none' }}
              />
            </React.Fragment>
          )
        })}
      </>
    )
  }
}

const targetLayer = (
  targets: TargetInterface[],
  color: Color,
  title: string,
  currency?: string,
) => {
  return ({ yScale, width, xScale, bars, data }: any) => {
    const barWidth = bars[0]?.width || 10
    if (!targets?.length) {
      return null
    }

    const maxIndex = targets.reduce(
      (prev, curr) => (prev > curr.end_index ? prev : curr.end_index),
      0,
    )
    const xCustomScale = scaleLinear().domain([0, maxIndex]).range([0, width])

    const getEndX = (target: TargetInterface, index: number) => {
      const endData = data[target.end_index]
      let endX = xCustomScale(0)

      if (endData && index !== 0) {
        endX = xScale(endData.progress_datetime) - barWidth * 0.2 - 1.5
      } else if (index === targets.length - 1) {
        endX = xCustomScale(maxIndex)
      }
      return endX
    }

    const lineGenerator = line<TargetInterface>()
      .defined(
        (target, i, targetList) =>
          target.target !== null || targetList[i + 1]?.target !== null,
      )
      .curve(curveStepBefore)
      .x(getEndX)
      .y(target => yScale(target.target))

    return (
      <>
        <path
          d={lineGenerator(targets!)!}
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="1"
          strokeDasharray="5,5"
          style={{ pointerEvents: 'none' }}
        />
        {targets.map((target, index) => {
          const endX = getEndX(target, index)

          return (
            <React.Fragment key={index}>
              {target.target !== null && target.review_cycle_name && (
                <Tooltip
                  isSvg
                  placement="top"
                  body={
                    <TargetTooltip target={target} currency={currency} title={title} />
                  }
                >
                  <circle
                    cx={endX}
                    cursor="pointer"
                    cy={yScale(target.target)}
                    r={3}
                    fill={color}
                  />
                </Tooltip>
              )}
            </React.Fragment>
          )
        })}
      </>
    )
  }
}

const getChartScaleInfo = (
  tableData: PerformanceChartCycles,
  keys: string[],
  findMin?: (minValue: number) => number,
  findMax?: (maxValue: number) => number,
) => {
  let maxValue = 0
  let minValue = 0
  let hasTarget = false

  tableData?.targets?.forEach(tableDataValue => {
    if (tableDataValue.target && tableDataValue.target > maxValue) {
      maxValue = tableDataValue.target
    }

    if (tableDataValue.target < minValue) {
      minValue = tableDataValue.target
    }

    if (tableDataValue.target) {
      hasTarget = true
    }
  })

  tableData.progress_history.forEach(tableDataValue => {
    const sumValue = [...keys, 'estimated', 'requisition_impact']
      .filter(key => key !== 'progress_datetime')
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      .map(key => tableDataValue[key])
      .filter(key => key)
      .reduce((acc, val) => acc + val, 0)

    if (sumValue > maxValue) {
      maxValue = sumValue
    }

    if (sumValue < minValue) {
      minValue = sumValue
    }
  })

  // allows adjusting or overriding the max/min values
  if (findMax) {
    maxValue = findMax(maxValue)
  }
  if (findMin) {
    minValue = findMin(minValue)
  }

  maxValue *= 1.16
  if (maxValue === 0) {
    maxValue = Math.abs(minValue) / 20
  }
  minValue *= 1.16

  return { maxValue, minValue, hasTarget }
}

type ParsedChartInfo = {
  parsedData: PerformanceChartCycles
  isStackedChart: boolean
  legendKeys?: string[]
}

const parseData = (data: PerformanceChartCycles): ParsedChartInfo => {
  let isStackedChart = false
  let hasLegendData = !!data.legend_items

  const stacksToProgressData = (stacks: StackInterface[]): any => {
    return stacks.reduce((acc, stack) => {
      const key = stack.legend_item.label
      acc[key] = stack.value

      return acc
    }, {} as any)
  }

  const parsedProgress = data.progress_history.map(progress => {
    if (progress.stacks) {
      isStackedChart = true
      return {
        ...progress,
        ...stacksToProgressData(progress.stacks),
      }
    }
    return progress
  })

  const legendKeys: string[] = []

  if (hasLegendData) {
    data.legend_items!.forEach(legend => {
      legendKeys.push(legend.label)
    })
  }

  return {
    parsedData: { ...data, progress_history: parsedProgress },
    legendKeys: hasLegendData ? legendKeys : undefined,
    isStackedChart,
  }
}

export const timeRangeTabs = [
  PerformanceTimeRange.day,
  PerformanceTimeRange.week,
  PerformanceTimeRange.month,
  PerformanceTimeRange.quarter,
]

export const BaseChartInner = ({
  cycleOffset,
  graphTimeRange = PerformanceTimeRange.month,
  id,
  fetchData,
  currency,
  isPercentageChart,
  legend,
  keys: propsKeys = ['progress'],
  colors,
  layers = [],
  data,
  showButtons,
  buttonSuffix,
  markers,
  findMaxValue,
  findMinValue,
  onClick,
  tooltip,
  axisBottom,
  axisLeft,
  margin,
  gridYValues,
  title,
  indexBy = 'progress_datetime',
  isNew,
  targetLayerTitle = 'Target',
  targetLayerColor = opacity(Token.colorChannel.deepGrey, 0.4),
  showTargetLegend = false,
  highlightHoveredCells = false,
  onGraphTimeRangeChange,
  legendMode = 'auto',
  onResize,
  theme: chartThemeOverrides = {},
  isSection = true,
  ...props
}: BaseInnerPropsInterface) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<HTMLDivElement>(null)
  const { width: containerWidth } = useResizeObserver({ ref: containerRef })
  const { width: chartWidth, height: chartHeight } = useResizeObserver({
    ref: chartRef,
  })

  const [graphTimeRangeState, setGraphTimeRange] = useState(
    showButtons ? graphTimeRange : null,
  )
  const [keys, setKeys] = useState<string[]>(propsKeys)
  const [hasLegendData, setHasLegendData] = useState<boolean>(false)
  const [hasStacks, setHasStacks] = useState<boolean>(false)
  const [focussedValue, setFocussedValue] = useState<Value>()

  const [tableData, setTableData] = useState<PerformanceChartCycles>(
    data || {
      targets: [],
      progress_history: [],
    },
  )
  const [fetchingError, setFetchingError] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    chartRef.current.style.height = getHeightStyle({
      showButtons,
      title,
      hasVerticalLayout,
    })
    onResize?.({ width: containerWidth || 0, height: chartHeight || 0 })
  }, [containerWidth])

  useEffect(() => {
    if (!chartRef.current || !containerWidth || !chartWidth || !chartHeight) {
      return
    }
    const ratio = chartHeight / chartWidth
    if (ratio > maxRatio) {
      // - 2 is here to prevent possible infinite updates caused by rounding errors
      chartRef.current.style.height = `${chartWidth * maxRatio - 2}px`
    }
  }, [containerWidth, chartWidth, chartHeight, chartRef.current, containerRef.current])

  useEffect(() => {
    let canceled = false

    async function fetchChartData(timeRange: PerformanceTimeRange) {
      try {
        if (!fetchData) {
          return
        }
        setLoading(true)
        const result = await fetchData(`${id}`, timeRange, cycleOffset)

        if (!canceled) {
          setLoading(false)
          if (result.data) {
            setupData(result.data)
          } else {
            setFetchingError('No data')
          }
        }
      } catch (e) {
        if (!canceled) {
          setLoading(false)
          setFetchingError('Something went wrong')
        }
      }
    }

    if (!data && graphTimeRangeState) {
      fetchChartData(graphTimeRangeState)
    }

    return () => {
      canceled = true
    }
  }, [graphTimeRangeState, cycleOffset, id])

  useEffect(() => {
    setGraphTimeRange(graphTimeRange)
  }, [graphTimeRange])

  useEffect(() => {
    if (data) {
      setupData(data)
    }
  }, [data])

  const setupData = (d: PerformanceChartCycles) => {
    const { parsedData, legendKeys, isStackedChart } = parseData(d)
    setHasLegendData(!!legendKeys)
    setHasStacks(isStackedChart)
    legendKeys?.length && setKeys(legendKeys)

    setTableData(parsedData)
  }

  const chartColors = useMemo(
    () =>
      hasLegendData
        ? colors || CHART_COLORS
        : colors || [
            Token.color.blue,
            Token.color.lightBlue,
            Token.color.green,
            Token.color.orange,
          ],
    [hasLegendData, colors],
  )

  const keyToColor = useMemo(
    () =>
      keys.reduce(
        (accumulator, key, index) => ({
          ...accumulator,
          [key]: isFunction(chartColors) ? chartColors({})[index] : chartColors[index],
        }),
        {},
      ),
    [keys, chartColors],
  )

  const getBarColor = (bar: any) => {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    const barColor = keyToColor[bar.id]
    if (focussedValue) {
      return bar.id === focussedValue ? barColor : colorUtil(barColor).alpha(0.2)
    }
    return barColor
  }

  const maxTicks = getMaxTicks(chartWidth, chartHeight)

  let dateFormat = 'dd MMM'
  if (graphTimeRangeState === PerformanceTimeRange.month) {
    dateFormat = 'MMM'
  }
  if (graphTimeRangeState === PerformanceTimeRange.quarter) {
    dateFormat = 'QQQ yyyy'
  }

  const { maxValue, minValue, hasTarget } = getChartScaleInfo(
    tableData,
    keys,
    findMinValue,
    findMaxValue,
  )

  const hasForecast = tableData?.progress_history?.some(progress => progress.estimated)

  const mappedTargets = tableData.targets?.flatMap(cycle => {
    return cycle.targets.map((target, i) => {
      // math.ceil just in case targets do not fit nicely into indexes
      const diff = Math.ceil((cycle.end_index - cycle.start_index) / cycle.targets.length)

      return {
        ...cycle,
        start_index: cycle.start_index + diff * i,
        end_index: cycle.start_index + diff * (i + 1),
        target,
        target_name: cycle.targets_names && cycle.targets_names[i],
      }
    })
  })

  const normalizedLayers = layers?.map(layer => {
    return layer(mappedTargets, currency)
  })

  const formatValue = (val: number) => {
    if (props.formatValue) {
      return props.formatValue(val)
    }
    if (currency) {
      return formatMoneyMillions(val, currency)
    }
    if (isPercentageChart) {
      return formatPercentage(val)
    }
    return formatNumberMillions(val)
  }

  const defaultTooltip = ({ value, indexValue, id: label, index }: any) => {
    const total = hasStacks ? tableData.progress_history[index]?.progress : value

    return (
      <TooltipContainer>
        {/* Date */}
        {isValid(new Date(indexValue))
          ? format(new Date(indexValue), dateFormat)
          : indexValue}

        {/* Value */}
        {total !== null && `: ${formatValue(total)}`}

        {/* Stack value */}
        {hasStacks && hasLegendData && (
          <>
            <br />
            {label}: {formatValue(Number(value))}
          </>
        )}
      </TooltipContainer>
    )
  }

  const chartTheme = useMemo(
    () =>
      merge(
        {
          axis: {
            ticks: {
              line: {
                stroke: 'gray',
                strokeWidth: 0,
              },
              text: {
                fill: Token.color.greyTone50,
              },
            },
            domain: {
              line: {
                strokeWidth: 0,
              },
            },
          },
          grid: {
            line: {
              strokeWidth: 0,
            },
          },
          fontSize: 12,
          background: Token.color.widgetBackground,
          tooltip: {
            container: {
              background: Token.color.popoverBackground,
              padding: '10px 15px',
              borderRadius: 4,
            },
          },
        },
        chartThemeOverrides,
      ),
    [chartThemeOverrides],
  ) as Theme

  const getSectionLabel = (label: string) => {
    const [name, year] = label.split(' ')
    return `${name} ${year ? `'${format(new Date(year), 'yy')}` : ''}`
  }

  const defaultAxisBottom: AxisProps = {
    format: (label: any) =>
      isValid(new Date(label)) ? format(new Date(label), dateFormat) : label,
    tickSize: 0,
    renderTick: ({ tickIndex, value, format: formatTick, x, y }: any) => {
      const label = getSectionLabel(value)
      const isProbation = label.includes('Probation')
      const tick = (
        <g transform={`translate(${x},${y + 22})`}>
          {!isSection && (
            <rect
              x={isProbation ? -30 : -20}
              y={isProbation ? -8 : -10}
              width={isProbation ? 61 : 41}
              height={17}
              fill={Token.color.popoverBackground}
              rx={5}
            />
          )}
          <text
            fontWeight={600}
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={isSection ? chartTheme.fontSize : 11}
            fill={isSection ? chartTheme.axis?.ticks?.text?.fill : Token.color.foreground}
          >
            {isSection ? formatTick(value) : label}
          </text>
        </g>
      )
      if (!maxTicks?.x) {
        return tick
      }

      const step = Math.ceil(tableData.progress_history.length / maxTicks.x)
      if (tickIndex % step === 0) {
        return tick
      }
      return null
    },
  }

  const defaultAxisLeft: AxisProps = {
    tickSize: 0,
    tickPadding: 8,
    tickRotation: 0,
    tickValues: maxTicks ? maxTicks.y : undefined,
    format: (d: any) => formatValue(d),
  }

  const isMobile = containerWidth
    ? containerWidth <= parseInt(Token.breakpoint.md, 10)
    : true
  const hasVerticalLayout = isMobile && (hasLegendData || legendMode === 'large')

  const defaultMargin = { top: 10, right: 40, bottom: 75, left: 16 }

  const newButtonGroup = isNew && (
    <Flex mb="s-20" mt="s-16" pl={{ all: '0px', md: '18px' }} alignItems="center">
      <TabBar variant="segmented">
        {timeRangeTabs.map(timeRange => {
          const isSelected = graphTimeRangeState === timeRange
          return (
            <TabBar.Item
              key={timeRange}
              aria-selected={isSelected}
              onClick={() => {
                onGraphTimeRangeChange?.(timeRange)
                setGraphTimeRange(timeRange)
              }}
            >
              <Text
                use="div"
                fontSize="small"
                px="s-12"
                color={isSelected ? 'light-blue' : 'grey-tone-50'}
                fontWeight={500}
              >
                {startCase(timeRange)}
              </Text>
            </TabBar.Item>
          )
        })}
      </TabBar>
      {buttonSuffix}
    </Flex>
  )

  // Should be reversed for the stacks to be displayed in the same order as legend
  const displayKeys = useMemo(() => {
    if (hasLegendData) {
      return [...keys].reverse()
    }
    return keys
  }, [hasLegendData, keys])

  return (
    <Flex width="100%" height="100%" ref={containerRef}>
      <Box
        flex="1 0 auto"
        height="100%"
        width="100%"
        pr={hasLegendData && keys.length > 0 && !hasVerticalLayout ? '260px' : '0px'}
      >
        {title && (
          <Text use="p" pt="1rem" fontWeight={500} pl={{ all: '0px', md: '18px' }}>
            {title}
          </Text>
        )}
        {showButtons && (
          <>
            {isNew ? (
              newButtonGroup
            ) : (
              <ButtonRow>
                <ButtonGroup
                  onChange={timeRange => {
                    onGraphTimeRangeChange?.(timeRange)
                    setGraphTimeRange(timeRange)
                  }}
                  value={graphTimeRangeState}
                  tabs={timeRangeTabs}
                />
              </ButtonRow>
            )}
          </>
        )}
        <SharedChartContainer
          showButtons={showButtons}
          hasVerticalLayout={hasVerticalLayout}
          title={title}
          ref={chartRef}
        >
          {(props.loading || loading) && <Loader type="Spinner" size="massive" />}
          {!loading && fetchingError && <FetchingError>{fetchingError}</FetchingError>}
          {!props.loading && !loading && !fetchingError && (
            <ResponsiveBar
              keys={displayKeys}
              data={tableData.progress_history}
              indexBy={indexBy}
              enableLabel={false}
              margin={margin || defaultMargin}
              padding={getBarPadding(chartWidth)}
              onMouseEnter={eventData => setFocussedValue(eventData.id)}
              onMouseLeave={() => setFocussedValue(undefined)}
              colors={highlightHoveredCells ? getBarColor : chartColors}
              theme={chartTheme}
              onClick={onClick}
              markers={markers}
              tooltip={tooltip || defaultTooltip}
              maxValue={maxValue}
              minValue={minValue}
              axisTop={null}
              axisRight={axisLeft || defaultAxisLeft}
              axisBottom={axisBottom || defaultAxisBottom}
              axisLeft={null}
              motionStiffness={90}
              motionDamping={15}
              gridYValues={gridYValues}
              layers={[
                BarLayerType.Grid,
                BarLayerType.Axes,
                BarLayerType.Markers,
                BarLayerType.Legends,
                predictionLayer(tooltip),
                BarLayerType.Bars,
                targetLayer(mappedTargets, targetLayerColor, targetLayerTitle, currency),
                ...(canShowCycles(chartWidth) ? [cycleLayer(tableData.targets)] : []),
                ...normalizedLayers,
              ]}
            />
          )}
          {!!tableData?.progress_history?.length &&
            (legend || (
              <Legend
                colors={isFunction(chartColors) ? chartColors({}) : chartColors}
                isBelow={hasVerticalLayout}
                hasTarget={showTargetLegend || hasTarget}
                hasForecast={hasForecast}
                targetLayerTitle={targetLayerTitle}
                targetLayerColor={targetLayerColor}
                legendItems={tableData.legend_items}
              />
            ))}
        </SharedChartContainer>
      </Box>
    </Flex>
  )
}
